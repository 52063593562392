import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';

import Transform from 'ol-ext/interaction/Transform';
import { Collection, Feature } from 'ol';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { undoRedoPush } from '../../mapLayer/mapInit';

type TransformOptionsType = {
  enableTransformSelection?: boolean;
};

const defaultStyle = {
  stroke: new Stroke({
    color: '#FF9800',
    width: 2
  }),
  fill: new Fill({
    color: '#FFEACC4F'
  }),
  pointStroke: new Stroke({
    color: '#FF9800',
    width: 2
  }),
  pointFill: new Fill({
    color: '#FFFFFF'
  })
};

const customStyle = {
  rotate: new Style({
    image: new Icon({
      src: '/rotate_icon.svg',
      anchor: [-0.1, -0.1]
    })
  })
};

class TransformTool extends ToolAbstract {
  private mapObj: MapBase;
  private transform: Transform | null;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.transform = null;
  }

  setFeaturesForTransformation = (features: Collection<Feature>) => {
    this.transform?.setSelection(features);
  };

  init() {
    this.on();
  }

  on(options = {} as TransformOptionsType) {
    this.off();
    const { enableTransformSelection = true } = options;
    this.transform = new Transform({
      scale: true,
      rotate: true,
      hitTolerance: 2,
      selection: enableTransformSelection
    });

    this.mapObj.map?.addInteraction(this.transform);

    this.transform.on('translateend', () => undoRedoPush());
    this.transform.on('scaleend', () => undoRedoPush());
    this.transform.on('rotateend', () => undoRedoPush());
    // Applying style asynchronously when all are set
    setTimeout(() => {
      try {
        if (this.transform) {
          this.transform.setDefaultStyle(defaultStyle);
          this.transform.setStyle('rotate', customStyle.rotate);
        }
      } catch (e) {
        if (process.env.APP_ENV === 'dev') {
          console.error(e);
        }
      }
    }, 100);
  }

  off() {
    this.transform && this.mapObj.map?.removeInteraction(this.transform);
  }
}

export default TransformTool;
