import React from "react";

const SVGpolygon = (props: any) => {
  const { strokeColor = "#000000" } = props;
  return (
    <svg
      width="1.5rem"
      height="2rem"
      viewBox="0 0 24 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="6" width="24" height="36" fill={strokeColor} />
    </svg>
  );
};

export default SVGpolygon;
