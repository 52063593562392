import { Translate } from 'ol/interaction';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { EVENT_TYPE, KEY_CODE } from '../../../hooks/tools/helpers/constants';
import { undoRedoPush } from '../../mapLayer/mapInit';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import { triggerOverrideOverlap } from '../../../helpers/helpers';
import { showToast } from 'ui';
import { selectStyle } from '../../../hooks/tools/helpers/styles';
import { FeatureisOutOfExtent } from 'macaw/src/getValidFeatures';
import { generateUniqueID } from 'macaw';
import { GEOMETRY_TYPE, MAP_TYPE } from 'woodpecker';
import { detectRightDoubleClick } from '../../../helpers/detectRightDoubleClick';
import { MOUSE_CLICK_EVENT } from '../../../constants';

class CopyAndMoveTool extends ToolAbstract {
  private mapObj: MapBase;
  private translate: Translate | null;
  private hitTolerance: number;
  private isTranslating: boolean;
  private finalFeature: any;
  private selected_feature: any;
  private isoverlap: boolean;
  private outsideMapevent: any;
  private layer: any;
  private features: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.translate = null;
    this.hitTolerance = 10;
    this.isTranslating = false;
    this.isoverlap = false;
    this.finalFeature = null;
    this.outsideMapevent = null;
    // this.timeTaken = 0;
  }

  init(id: string) {
    this.off();
    if (!globalStore.AppStore?.selectedFeatures?.length) {
      showToast('Please select some features', 'error');
      globalStore.AppStore.setTool({ tool_id: 'select_feature' });
      return;
    }

    this.layer = this.mapObj?.getLayerById(id);
    this.features = new Collection<Feature>(
      globalStore.AppStore.selectedFeatures.map((feature: Feature) => {
        // Apply your custom style here, assuming `style` is a function that returns a style.
        const feat_vector_layer_id = feature?.getProperties()?.['vector_layer_id'];
        const layerData = globalStore?.AppStore?.layers?.get(feat_vector_layer_id) || {};
        feature.setStyle(
          selectStyle(
            layerData?.geometry_type === GEOMETRY_TYPE.ABSTRACT,
            layerData?.geometry_type === GEOMETRY_TYPE.TYPICAL
          )
        );
        return feature;
      })
    );

    // globalStore.AppStore.setSelectedFeatures([]);

    this.translate = new Translate({
      layers: [this.layer],
      hitTolerance: this.hitTolerance
    });

    this.translate = new Translate({
      layers: [this.layer],
      hitTolerance: this.hitTolerance,
      features: this.features
    });

    this.translate.handleEvent = e => {
      this.outsideMapevent = e;
      if (e.originalEvent.which === KEY_CODE.LEFT_CLICK && e.type === EVENT_TYPE.CLICK && !this.isTranslating) {
        //@ts-ignore
        this.translate?.handleDownEvent(e);
      } else if (e.originalEvent.which === KEY_CODE.LEFT_CLICK && e.type === EVENT_TYPE.CLICK && this.isTranslating) {
        this.onLeftClick(e);
      } else if (e.type === EVENT_TYPE.POINTER_MOVE) {
        //@ts-ignore
        this.translate.handleMoveEvent(e);
        //@ts-ignore
        this.translate.handleDragEvent(e);
      } else if (e.originalEvent.button === MOUSE_CLICK_EVENT.RIGHT_CLICK) {
        const isRightDoubleClicked = detectRightDoubleClick(e);
        if (isRightDoubleClicked) {
          this.off();
          const selectedTool = globalStore.AppStore.tool;
          globalStore.AppStore.setTool({ ...selectedTool?.lastTool, clickId: Math.random().toString(36).substring(7) });
          return false;
        }
      }

      return true;
    };
    this.mapObj.map?.addInteraction(this.translate);

    this.mapObj.map?.on('pointermove', this.pointerMoveHandler);
    this.translate.on('translateend', this.onTranslateEnd);
    this.translate.on('translatestart', this.onTranslateStart);
  }
  /**
   * Event handler for the translatestart event.
   * Sets the isTranslating.current flag to true.
   * Clones the finalFeature and adds the clone to the selectedLayer's source.
   * @param e - The translatestart event object.
   */
  onTranslateStart = (e: any) => {
    // this.timeTaken = Date.now();
    this.features?.forEach((feature: any) => {
      // Apply your custom style here, assuming `style` is a function that returns a style.
      feature.setStyle(null);
    });
    this.isTranslating = true;
    this.finalFeature = e.features.getArray();
    this.selected_feature = e.features.getArray() as Feature[];

    this.selected_feature?.forEach((feature: Feature, index: number) => {
      const vector_layer_id = feature.getProperties()['vector_layer_id'];
      const source = this.mapObj.getLayerById(vector_layer_id).getSource();
      const featureClone = feature.clone();
      const unique_id = generateUniqueID() + `${Math.round(Math.random() * 100)}` + index;
      featureClone?.setId(unique_id);
      source?.addFeature(featureClone);
    });
  };

  /**
   * Event handler for the left click event during translation.
   * Clones the finalFeature and updates the selectedFeatureRef.
   * Adds the selectedFeatureRef to the selectedLayer's source.
   * Removes the finalFeature from the selectedLayer's source and adds it back.
   */
  onLeftClick = (e: any) => {
    // @ts-ignore: Unreachable code error
    let isOutOfExtent = false;
    if (this.mapObj.map_type === MAP_TYPE.BLUEPRINT) {
      this.finalFeature.forEach((feature: any) => {
        isOutOfExtent = isOutOfExtent || FeatureisOutOfExtent(feature?.getGeometry()?.getExtent(), this.mapObj.map);
      });
    }

    if (isOutOfExtent) {
      return true;
    }

    this.selected_feature = this.finalFeature;
    this.selected_feature?.forEach((feature: any, index: number) => {
      const vector_layer_id = feature.getProperties()['vector_layer_id'];
      const source = this.mapObj.getLayerById(vector_layer_id).getSource();
      const featureClone = feature.clone();
      const unique_id = generateUniqueID() + `${Math.round(Math.random() * 100)}` + index;
      featureClone?.setId(unique_id);
      source.addFeature(featureClone);
      source.removeFeature(feature);

      triggerOverrideOverlap(featureClone);
    });
    undoRedoPush();

    this.finalFeature?.forEach((feature: Feature, index: number) => {
      const vector_layer_id = feature.getProperties()['vector_layer_id'];
      const source = this.mapObj.getLayerById(vector_layer_id).getSource();
      const unique_id_final = generateUniqueID() + `${Math.round(Math.random() * 100)}` + index;
      feature?.setId(unique_id_final);
      source.addFeature(feature);
    });
    // updatePointCount();
  };

  /**
   * Event handler for the translateend event.
   * Gets the finalFeature from the event object.
   * Sets the isOverlap flag to true.
   * If there is an overlap, removes the finalFeature from the selectedLayer's source.
   * Otherwise, todo
   */
  onTranslateEnd = (e: any) => {
    try {
      const finalFeature = e.features.getArray();
      let isOverlap = true;
      if (isOverlap) {
        finalFeature.forEach((feature: Feature) => {
          const vector_layer_id = feature.getProperties()['vector_layer_id'];
          const source = this.mapObj.getLayerById(vector_layer_id).getSource();
          source.removeFeature(feature);
        });
      } else {
        undoRedoPush();
      }
      globalStore.AppStore.setSelectedFeatures([]);
    } catch (error) {}
  };
  pointerMoveHandler = (e: any) => {
    let canvas = document.getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.style.cursor = '';

      this.mapObj.map?.forEachFeatureAtPixel(
        e.pixel,
        (feature, layer) => {
          if (feature) {
            canvas.style.cursor = 'move';
          }
        },
        {
          layerFilter: layer_candidate => {
            return this.layer == layer_candidate;
          },
          hitTolerance: this.hitTolerance
        }
      );
    }
  };
  off() {
    //edge case handling
    let canvas = document.getElementsByTagName('canvas')[0];

    if (canvas) canvas.style.cursor = '';

    this.features?.forEach((feature: any) => {
      // Apply your custom style here, assuming `style` is a function that returns a style.
      feature.setStyle(null);
    });

    //@ts-ignore private events thats why giving error
    this.translate?.handleUpEvent(this.outsideMapevent);
    this.isTranslating = false;
    this.isoverlap = false;

    this.mapObj.map?.un('pointermove', this.pointerMoveHandler);
    this.translate && this.mapObj.map?.removeInteraction(this.translate);
    this.translate && this.translate.un('translateend', this.onTranslateEnd);
  }
}

export default CopyAndMoveTool;
