import React from "react";

const FlipHorizontalIcon = ({ fill = "#4D4D4D" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75305 13.3951H3.38308C3.00025 13.3951 2.67403 13.2603 2.40442 12.9907C2.13481 12.721 2 12.3948 2 12.012V3.58805C2 3.20522 2.13481 2.879 2.40442 2.60939C2.67403 2.33978 3.00025 2.20497 3.38308 2.20497H5.75305V3.58805H3.38308V12.012H5.75305V13.3951ZM6.91442 14.6V1H8.29751V14.6H6.91442ZM11.807 3.58805V2.20497C12.1899 2.20497 12.5161 2.33978 12.7857 2.60939C13.0553 2.879 13.1901 3.20522 13.1901 3.58805H11.807ZM11.807 8.42977V7.17027H13.1901V8.42977H11.807ZM11.807 13.3951V12.012H13.1901C13.1901 12.3948 13.0553 12.721 12.7857 12.9907C12.5161 13.2603 12.1899 13.3951 11.807 13.3951ZM11.807 6.00891V4.74942H13.1901V6.00891H11.807ZM11.807 10.8506V9.59114H13.1901V10.8506H11.807ZM9.45886 13.3951V12.012H10.6457V13.3951H9.45886ZM9.45886 3.58805V2.20497H10.6457V3.58805H9.45886Z"
      fill="#666666"
    />
  </svg>
);

export default FlipHorizontalIcon;
