import { MapBrowserEvent } from 'ol';
import { Draw } from 'ol/interaction';
import { MOUSE_CLICK_EVENT } from '../constants';
import { TOOL_TYPE } from '../js-layer/toolLayer/constants';
import { globalStore } from '../js-layer/utilityclasses/AppStoreListener';
import { DEFAULT_SELECTED_TOOL } from '../store/mapStore';
import { detectRightDoubleClick } from './detectRightDoubleClick';

export const canEnablePanning = (e: MouseEvent, activeToolId: string): boolean => {
  if (activeToolId === DEFAULT_SELECTED_TOOL.tool_id) return true;
  if (activeToolId === TOOL_TYPE.COPY_COORDS) return false;
  if (e.button === MOUSE_CLICK_EVENT.SCROLL_CLICK) return true;
  const isCtrlPressed = e.ctrlKey || e.metaKey;
  return e.button === MOUSE_CLICK_EVENT.RIGHT_CLICK && (isCtrlPressed || canAutoFinishDrawing());
};

export const canAutoFinishDrawing = () => !globalStore?.AppStore?.toolsSetting?.endDrawingOnRightDoubleClick;

export const drawHandler = (
  e: MapBrowserEvent<UIEvent>,
  draw: Draw,
  options: {
    avoidDrawingCallback?: () => boolean;
    validateFeatureCallback?: () => boolean;
  } = {}
) => {
  const { avoidDrawingCallback = () => false, validateFeatureCallback = () => true } = options;
  try {
    const clickedBtn = (e.originalEvent as MouseEvent).button;
    if (clickedBtn === MOUSE_CLICK_EVENT.RIGHT_CLICK && !canAutoFinishDrawing()) {
      const isDoubleClicked = detectRightDoubleClick(e);
      if (isDoubleClicked) {
        draw?.appendCoordinates([e.coordinate]);
        if (validateFeatureCallback()) {
          draw?.finishDrawing();
          return false;
        }
      }
    }
    if (
      clickedBtn === MOUSE_CLICK_EVENT.SCROLL_CLICK ||
      clickedBtn === MOUSE_CLICK_EVENT.RIGHT_CLICK ||
      avoidDrawingCallback()
    ) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};
