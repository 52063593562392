import React from "react";

const FlipVertical = ({ fill = "#4D4D4D" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.60492 5.75305L2.60493 3.38308C2.60493 3.00025 2.73973 2.67403 3.00934 2.40442C3.27896 2.13481 3.60518 2 3.98801 2L12.4119 2C12.7948 2 13.121 2.13481 13.3906 2.40442C13.6602 2.67403 13.795 3.00025 13.795 3.38308L13.795 5.75305L12.4119 5.75305L12.4119 3.38308L3.98801 3.38308L3.98801 5.75305L2.60492 5.75305ZM1.4 6.91442L15 6.91442L15 8.29751L1.4 8.2975L1.4 6.91442ZM12.4119 11.807L13.795 11.807C13.795 12.1899 13.6602 12.5161 13.3906 12.7857C13.121 13.0553 12.7948 13.1901 12.4119 13.1901L12.4119 11.807ZM7.57023 11.807L8.82973 11.807L8.82973 13.1901L7.57023 13.1901L7.57023 11.807ZM2.60492 11.807L3.98801 11.807L3.98801 13.1901C3.60518 13.1901 3.27895 13.0553 3.00934 12.7857C2.73973 12.5161 2.60492 12.1899 2.60492 11.807ZM9.99109 11.807L11.2506 11.807L11.2506 13.1901L9.99109 13.1901L9.99109 11.807ZM5.14937 11.807L6.40886 11.807L6.40886 13.1901L5.14937 13.1901L5.14937 11.807ZM2.60492 9.45886L3.98801 9.45886L3.98801 10.6457L2.60492 10.6457L2.60492 9.45886ZM12.4119 9.45886L13.795 9.45886L13.795 10.6457L12.4119 10.6457L12.4119 9.45886Z"
      fill="#666666"
    />
  </svg>
);

export default FlipVertical;
