import { Draw } from 'ol/interaction';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import * as turf from '@turf/turf';
import MapBase from '../../mapLayer/mapBase';
import { DATA_PROJECTION, FEATURE_PROJECTION, GEOMETRY_TYPES, MAP_TYPE } from 'woodpecker';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { isOutOfExtent, triggerOverrideOverlap } from '../../../helpers/helpers';
import { canAutoFinishDrawing, drawHandler } from '../../../helpers/interactionUtils';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import Interaction from 'ol/interaction/Interaction';
import { generateUniqueID, getRectangleCoordinates, isValid } from 'macaw';
import { showToast } from 'ui';
import { drawStyle, labelStyle } from '../../../hooks/tools/helpers/styles';
import { formatArea } from '../../../hooks/tools/helpers';

class AddRectangle extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null = null;
  private snap: Interaction[] | null = null;
  private layer: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.snap = null;
    // this.listner = null;
  }

  /**
   * A function that constructs or updates the geometry of the drawn rectangle based on the coordinates.
   * @param coordinates - The coordinates of the rectangle.
   * @param geometry - The existing geometry object, if any.
   * @returns The updated geometry object.
   */
  geometryFunction = (coordinates: any, geometry: any) => {
    if (!geometry) {
      geometry = new Polygon(coordinates);
    } else {
      geometry.setCoordinates(coordinates);
    }
    const points = coordinates[0];
    if (points && points.length > 1) {
      const first_point = turf.point(
        new Point(points[0])
          .transform(FEATURE_PROJECTION, DATA_PROJECTION)
          // @ts-ignore: Unreachable code error
          .getCoordinates()
      );
      const second_point = turf.point(
        new Point(points[1])
          .transform(FEATURE_PROJECTION, DATA_PROJECTION)
          // @ts-ignore: Unreachable code error
          .getCoordinates()
      );

      if (points.length > 2) {
        const third_point = turf.point(
          new Point(points[2])
            .transform(FEATURE_PROJECTION, DATA_PROJECTION)
            // @ts-ignore: Unreachable code error
            .getCoordinates()
        );

        let final_coordinates = getRectangleCoordinates(first_point, second_point, third_point);

        final_coordinates = final_coordinates.map((pt: any) => {
          return (
            new Point(pt.geometry.coordinates)
              .transform(DATA_PROJECTION, FEATURE_PROJECTION)
              // @ts-ignore: Unreachable code error
              .getCoordinates()
          );
        });
        geometry.setCoordinates([final_coordinates]);
      }
      return geometry;
    }
  };

  /**
   * Event handler for the Backspace key press event.
   * @param event - The keydown event object.
   */
  keyDownHandler = (event: any) => {
    if (event.code === 'Backspace') {
      this.draw?.removeLastPoint();
    }
  };

  onDrawEnd = (event: any) => {
    const feature = event.feature;
    try {
      if (feature.getGeometry().getType() === 'Polygon') {
        const isIntersecting = isValid(feature);
        if (!isIntersecting) {
          showToast('Invalid feature: Intersecting polygon not allowed', 'error', {
            position: 'top-center',
            hideProgressBar: false
          });
          setTimeout(() => {
            this.layer.getSource().removeFeature(feature);
          }, 0);
        } else {
          const unq_id = generateUniqueID('polygon');
          feature.setId(unq_id);
          setTimeout(() => {
            triggerOverrideOverlap(feature);
            undoRedoPush();
          }, 0);
        }
      }
    } catch (error) {}
  };

  styleFunction = (feature: any) => {
    let styles = [drawStyle()];
    const { dpi, scale } = globalStore.AppStore.worksheetParams;
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.POLYGON && scale !== null) {
      //disable/enable live measurement
      if (this.mapObj.map_type === MAP_TYPE.AERIAL && !globalStore?.AppStore?.tool?.live_measurement) {
        return styles;
      }

      const label = formatArea(geometry, dpi, scale);
      const _labelStyle = labelStyle.clone();
      _labelStyle.setGeometry(geometry);
      _labelStyle.getText().setText(label);
      styles.push(_labelStyle);
    }
    return styles;
  };

  init = (id: string) => {
    this.layer = this.mapObj.getLayerById(id);
    if (this.layer) {
      const source = this.layer.getSource();

      this.draw = new Draw({
        source: source,
        type: 'Polygon',
        style: (feat: any, res) => {
          if (feat?.getGeometry().getType() == 'LineString') {
            return null;
          }
          return this.styleFunction(feat) as any;
        },
        dragVertexDelay: 0,
        snapTolerance: 1,
        maxPoints: 3,
        finishCondition: canAutoFinishDrawing,
        condition: e =>
          drawHandler(e, this.draw as Draw, { avoidDrawingCallback: () => isOutOfExtent(e, this.mapObj.map) }),
        geometryFunction: this.geometryFunction
      });

      this.mapObj.map?.addInteraction(this.draw);

      this.draw.on('drawend', this.onDrawEnd);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  };

  off = () => {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.draw && this.draw.un('drawend', this.onDrawEnd);
    window.removeEventListener('keydown', this.keyDownHandler);
  };
}

export default AddRectangle;
