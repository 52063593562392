import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import { mergeFeatures } from 'macaw';
import Select from 'ol/interaction/Select';
import { click, pointerMove } from 'ol/events/condition';

import { GEOMETRY_TYPE_STRING, MAP_LAYERS } from '../../../Constants/Constant';
import { highlightStyle } from '../MapBase';
import { layerTracker } from '../MapInit';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { adjustOverlayPosition, isNumericalLayer, isTypicalLayer } from '../../../Utils/HelperFunctions';

class ReClassifier extends Observer {
  domElements: $TSFixMe;

  hover: $TSFixMe;

  mapObj: $TSFixMe;

  select: $TSFixMe;

  selectableGeometry: $TSFixMe;
  shouldMergeFeatures: boolean;

  constructor(mapObj: $TSFixMe, shouldMergeFeatures = false) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.hover = null;
    this.domElements = null;
    this.selectableGeometry = null;
    this.shouldMergeFeatures = shouldMergeFeatures;
  }

  on() {
    this.mapObj.map.on('pointermove', this.changeCursor);

    this.hover = new Select({
      condition: pointerMove,
      toggleCondition: () => false,
      filter: (feature, layer) => {
        // Do not select parcel layer
        if (layer?.get('name') === MAP_LAYERS.OUTPUT && !isNumericalLayer(layer) && !isTypicalLayer(layer)) {
          return true;
        }
        return false;
      },
      style: feature => {
        const layer = this.mapObj.getLayerById(feature.get('layerId'));
        // @ts-expect-error TS(2322): Type 'FeatureLike' is not assignable to type 'null... Remove this comment to see the full error message
        return highlightStyle({ layer, feature });
      }
    });
    this.mapObj.map.addInteraction(this.hover);

    this.select = new Select({
      // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; multi: false; ... Remove this comment to see the full error message
      wrapX: false,
      multi: false,
      condition: click,
      toggleCondition: click,
      filter: (feature, layer) => {
        // Do not select parcel layer
        if (layer?.get('name') === MAP_LAYERS.OUTPUT && !isNumericalLayer(layer) && !isTypicalLayer(layer)) {
          return true;
        }
        return false;
      }
    });
    this.mapObj.map.addInteraction(this.select);

    this.select.on('select', this.showFeatureContainer);
  }

  showFeatureContainer = (e: $TSFixMe) => {
    this.domElements = {
      container: document.getElementById('reclassifier-container')
    };

    this.hover.getFeatures().clear();

    // Do not select line/point feature if first selected feature is polygon (for all combinations)
    const selectedFeatures = this.select.getFeatures();
    const selectedFeaturesArray = selectedFeatures.getArray();
    const selectableGeometryType =
      selectedFeaturesArray.length === 1 ? selectedFeaturesArray[0].getGeometry().getType() : null;
    if (this.shouldMergeFeatures && !this.selectableGeometry) {
      if (selectableGeometryType !== GEOMETRY_TYPE_STRING.POLYGON) {
        selectedFeatures.pop();
        return;
      } else {
        this.selectableGeometry = GEOMETRY_TYPE_STRING.POLYGON;
      }
    } else if (selectableGeometryType && !this.selectableGeometry) {
      this.selectableGeometry = selectableGeometryType;
    } else if (selectedFeaturesArray.length > 1 && this.selectableGeometry) {
      const lastAddedFeature = selectedFeatures.item(selectedFeaturesArray.length - 1);
      if (lastAddedFeature.getGeometry().getType() !== this.selectableGeometry) {
        selectedFeatures.pop();
        return;
      }
    }

    this.notifyObservers(TOOL_EVENT.LASSO_TOOL_GEOMETRY, this.selectableGeometry);

    const container = this.domElements?.container;
    if (!container) return;

    container.style.display = 'block';

    const { offsetWidth: overlayWidth, offsetHeight: overlayHeight } = container || {};
    const { pageX, pageY } = e.mapBrowserEvent.originalEvent || {};
    const [positionX, positionY] = adjustOverlayPosition({
      pageX,
      pageY,
      overlayWidth,
      overlayHeight
    });

    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    container.style.left = `${parseInt(positionX, 10)}px`;
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    container.style.top = `${parseInt(positionY, 10)}px`;

    // Hide select box user unselects all features
    if (!this.select.getFeatures().getLength()) {
      container.style.display = 'none';
    }
  };

  moveFeaturesToLayer(targetLayerName: $TSFixMe) {
    const features: Collection<Feature> = this.select.getFeatures();
    const targetLayer = this.mapObj.getLayerById(targetLayerName);
    if (targetLayer) {
      const targetLayerId = targetLayer.get('id');
      features.forEach((feature: $TSFixMe) => {
        const layerId = feature.get('layerId');
        const layer = this.mapObj.getLayerById(layerId);

        if (feature && layer && layerId !== targetLayerId) {
          feature.setProperties({ layerId: targetLayerId });
          targetLayer.getSource().addFeature(feature);

          layer.getSource().removeFeature(feature);

          layerTracker.push(layer.get('name'), layerId);
          layerTracker.push(targetLayer.get('name'), targetLayerId);
        }
      });
      try {
        if (this.shouldMergeFeatures && features.getLength() > 1) {
          const featuresArray = features.getArray();
          const areAllPolygons = featuresArray.every(f => f.getGeometry()?.getType() === GEOMETRY_TYPE_STRING.POLYGON);
          if (areAllPolygons) {
            mergeFeatures(featuresArray, targetLayer);
          }
        }
      } catch (e) {
        if (process.env.APP_ENV === 'dev') {
          console.error(e);
        }
      }
      this.notifyObservers(TOOL_EVENT.RECLASSIFY_TOOL);
    }

    this.closePopup();
  }

  closePopup() {
    this.select.getFeatures().clear();
    this.hover.getFeatures().clear();
    this.domElements.container.style.display = 'none';
    this.selectableGeometry = null;
  }

  changeCursor = (e: $TSFixMe) => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    document.getElementById('map').style.cursor = 'default';
    this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
      if (_layer?.get('name') === MAP_LAYERS.OUTPUT && !isNumericalLayer(_layer) && !isTypicalLayer(_layer)) {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        document.getElementById('map').style.cursor = 'pointer';
        return true;
      }
      return false;
    });
  };

  off() {
    this.mapObj.map.un('pointermove', this.changeCursor);
    if (this.domElements?.container) {
      this.domElements.container.style.display = 'none';
    }
    if (this.select) {
      this.select.getFeatures().clear();
      this.select.un('select', this.showFeatureContainer);
      this.mapObj.map.removeInteraction(this.select);
    }

    this.mapObj.map.removeInteraction(this.hover);
    this.selectableGeometry = null;
  }
}

export default ReClassifier;
