import RotateTool from '../tools/RotateGeometry';
import MapBase from '../../mapLayer/mapBase';

class Rotate {
  mapObj: MapBase;
  rotateTool: RotateTool;

  constructor(mapObj: any) {
    this.mapObj = mapObj;
    this.rotateTool = new RotateTool(mapObj);
  }
}

class RotateClockwise extends Rotate {
  execute() {
    this.rotateTool.on({ angle: -Math.PI / 2 });
    this.rotateTool.off();
  }
}

class RotateAntiClockwise extends Rotate {
  execute() {
    this.rotateTool.on({ angle: Math.PI / 2 });
    this.rotateTool.off();
  }
}
export { RotateClockwise, RotateAntiClockwise };
