import React from "react";

const MergeIcon = ({ fill = "#666666" }) => {
  return (
    <svg
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_862_16994)">
        <path
          d="M8.78918 10.255C8.99156 10.9956 9.43179 11.6492 10.0421 12.1151C10.6524 12.581 11.3989 12.8333 12.1667 12.8333H16.8334C18.2077 12.8334 19.5379 13.3187 20.5895 14.2036C21.6411 15.0885 22.3465 16.3162 22.5815 17.6703C23.3733 17.9286 24.0471 18.4608 24.4819 19.1712C24.9167 19.8816 25.0839 20.7237 24.9537 21.5464C24.8234 22.369 24.4041 23.1183 23.7711 23.6596C23.1382 24.201 22.3329 24.4989 21.5 24.5C20.6851 24.5006 19.8956 24.2169 19.2675 23.6977C18.6394 23.1786 18.2121 22.4565 18.0594 21.6561C17.9066 20.8556 18.0379 20.027 18.4307 19.313C18.8234 18.599 19.453 18.0445 20.2109 17.745C20.0085 17.0043 19.5682 16.3507 18.958 15.8848C18.3477 15.419 17.6011 15.1666 16.8334 15.1666H12.1667C10.9042 15.1685 9.67554 14.7589 8.66668 14V17.6983C9.44543 17.9735 10.1018 18.5152 10.5198 19.2276C10.9377 19.9399 11.0904 20.7772 10.9508 21.5912C10.8112 22.4053 10.3883 23.1438 9.75678 23.6761C9.12532 24.2085 8.32596 24.5005 7.50002 24.5005C6.67407 24.5005 5.87472 24.2085 5.24325 23.6761C4.61178 23.1438 4.18886 22.4053 4.04924 21.5912C3.90962 20.7772 4.06229 19.9399 4.48027 19.2276C4.89824 18.5152 5.55461 17.9735 6.33335 17.6983V10.3016C5.55982 10.0285 4.90675 9.49247 4.4881 8.78702C4.06944 8.08157 3.91179 7.25151 4.04264 6.44169C4.1735 5.63187 4.58456 4.89371 5.20409 4.35602C5.82362 3.81832 6.61228 3.51524 7.43246 3.49966C8.25263 3.48407 9.05224 3.75697 9.69175 4.27074C10.3313 4.78451 10.7701 5.50652 10.9316 6.31079C11.0931 7.11505 10.9671 7.9505 10.5756 8.67135C10.184 9.39219 9.55178 9.95266 8.78918 10.255Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_862_16994">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MergeIcon;
