const RotateClockwiseIcon = ({ fill = "#666666" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_583_867)">
      <path
        d="M13.5574 6.71369L14.9342 5.33611L16 6.40187L12.8042 9.5969L9.60844 6.40187L10.6742 5.33611L12.051 6.71369V4.76595C12.051 4.16667 11.813 3.59194 11.3892 3.16819C10.9655 2.74444 10.3907 2.50638 9.79146 2.50638H6.7787V1H9.79146C10.7903 1 11.7481 1.39677 12.4544 2.10302C13.1606 2.80927 13.5574 3.76716 13.5574 4.76595V6.71369ZM8.28508 5.51914C8.48484 5.51914 8.67642 5.59849 8.81767 5.73974C8.95892 5.88099 9.03827 6.07257 9.03827 6.27233V13.8042C9.03827 14.004 8.95892 14.1956 8.81767 14.3368C8.67642 14.4781 8.48484 14.5574 8.28508 14.5574H0.753189C0.553431 14.5574 0.361854 14.4781 0.220604 14.3368C0.0793536 14.1956 0 14.004 0 13.8042V6.27233C0 6.07257 0.0793536 5.88099 0.220604 5.73974C0.361854 5.59849 0.553431 5.51914 0.753189 5.51914H8.28508ZM7.53189 7.02551H1.50638V13.051H7.53189V7.02551Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_583_867">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RotateClockwiseIcon;
