abstract class ToolAbstract<T = unknown> {
  constructor() {}

  abstract init(
    id: string,
    featureTracing?: boolean,
    mode?: string,
    addTempLayer?: ((id: string, geojson: any) => void) | null
  ): void;

  abstract off(): void;

  on?(options?: T): void;
}

export default ToolAbstract;
