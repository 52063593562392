import buffer from '@turf/buffer';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import Modify from 'ol/interaction/Modify';
import VectorSource from 'ol/source/Vector';
import { never } from 'ol/events/condition';
import { Circle, LineString } from 'ol/geom';
import Polygon, { fromCircle, fromExtent } from 'ol/geom/Polygon';
import Draw, { createBox, createRegularPolygon } from 'ol/interaction/Draw';

import message from 'antd/es/message';

import { getDrawStyle, MODIFY_STYLE } from '../MapBase';
import { layerTracker } from '../MapInit';
import {
  GEOMETRY_TYPE,
  GEOMETRY_TYPE_STRING,
  GEOMETRY_TYPE_ENUM,
  LAYER_INDEX,
  MAP_LAYERS,
  TOOLS_ID
} from '../../../Constants/Constant';
import { bezier, getAreaStyleFunction, applyOverlapSettings } from '../../../Utils/olutils';
import { changeMapCursor, getNumericalPopupInfo, isNumericalLayer } from '../../../Utils/HelperFunctions';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { useRequest } from '../../../Stores/Request';
import { useToolData } from '../../../Stores/ToolData';

class AddFeatures extends Observer {
  areaStyleCache: $TSFixMe;

  baseLayerProps: $TSFixMe;

  bpLotExtent: $TSFixMe;

  currentFeature: $TSFixMe;

  domElements: $TSFixMe;

  draw: any;

  geomType: string;

  geometryFunction: $TSFixMe;

  geometryType: number;

  invalidSpace: boolean;

  isCurvedLine: boolean;

  isDrawActive: boolean;

  isFreeHand: boolean;

  isRegularShape: boolean;

  isShiftKeyPressed: boolean;

  lastGeometry: $TSFixMe;

  layer: $TSFixMe;

  layerId: $TSFixMe;

  lotFeature: $TSFixMe;

  mapObj: $TSFixMe;

  modify: $TSFixMe;

  toolId: $TSFixMe;

  temporaryFeature: Feature | null;

  constructor(mapObj: any) {
    super();
    this.mapObj = mapObj;
    this.modify = null;
    this.draw = null;
    this.layer = null;
    this.domElements = null;
    this.currentFeature = null;
    this.baseLayerProps = null;
    this.layerId = null;
    this.invalidSpace = false;
    this.areaStyleCache = [];
    this.lotFeature = null;
    this.bpLotExtent = null;
    this.geometryFunction = null;
    this.geomType = GEOMETRY_TYPE_STRING.POLYGON;
    this.toolId = null;
    this.isRegularShape = false;
    this.isShiftKeyPressed = false;
    this.isFreeHand = false;
    this.geometryType = GEOMETRY_TYPE_ENUM.POLYGON;
    this.isDrawActive = false;
    this.isCurvedLine = false;
    this.temporaryFeature = null;
  }

  on({ geometry: geometryType, layerId }: { geometry: number; layerId: string }) {
    this.off();
    if (!layerId) return;

    this.geometryType = geometryType;
    // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
    this.toolId = useRequest.getState()?.toolbar?.active?.toolId;
    this.isRegularShape = [TOOLS_ID.ADD_RECTANGLE, TOOLS_ID.ADD_CIRCLE, TOOLS_ID.ADD_CURVE].includes(this.toolId);
    this.isFreeHand = TOOLS_ID?.FREE_HAND === this.toolId;
    this.isCurvedLine = TOOLS_ID?.ADD_CURVE === this.toolId;
    if (this.isCurvedLine) {
      this.geomType = GEOMETRY_TYPE_STRING.LINESTRING;
    } else if (this.isRegularShape) {
      this.geomType = GEOMETRY_TYPE_STRING.CIRCLE;
    } else {
      this.geomType = GEOMETRY_TYPE[geometryType] || GEOMETRY_TYPE_STRING.POLYGON;
    }

    this.mapObj.map.on('pointermove', this.highlightFeatureOnHover);

    if (this.mapObj.isBlueprintMap) {
      this.baseLayerProps = this.mapObj.baseLayer?.getProperties() || {};
      const polygon = fromExtent(this.baseLayerProps.bp_page_extent);
      const feature = new Feature(polygon);
      this.bpLotExtent = feature;
    }

    this.layerId = layerId;

    const src = new VectorSource({ wrapX: false });
    this.layer = new VectorLayer({
      // @ts-expect-error TS(2345): Argument of type '{ id: string; source: VectorSour... Remove this comment to see the full error message
      id: 'empty-draw-layer',
      source: src,
      name: MAP_LAYERS.OUTPUT,
      style: MODIFY_STYLE,
      zIndex: LAYER_INDEX.DRAW
    });

    this.mapObj.addLayer(this.layer);

    switch (this.toolId) {
      case TOOLS_ID.ADD_RECTANGLE:
        this.geometryFunction = this.createBox;
        break;
      case TOOLS_ID.ADD_CIRCLE:
        this.geometryFunction = this.createCircle;
        break;
      case TOOLS_ID.ADD_CURVE:
        this.geometryFunction = this.drawCurve;
        break;
      default:
        this.geometryFunction = null;
    }

    this.draw = new Draw({
      type: this.geomType,
      source: src,
      ...(this.isRegularShape && { geometryFunction: this.geometryFunction }),
      ...(this.isFreeHand && { freehand: true }),
      style: getDrawStyle({
        applyMoreStyle: (feature: $TSFixMe, resolution: $TSFixMe, style: $TSFixMe) => {
          const areaStyle = getAreaStyleFunction({
            feature,
            areaLabelStyleCache: this.areaStyleCache,
            options: { measureArea: true, measureLength: true },
            mapInfo: { isBlueprintMap: this.mapObj.isBlueprintMap }
          });
          if (areaStyle.length) style = style.concat(areaStyle);

          return style;
        }
      }),
      condition: e => {
        const mouseClick = e.originalEvent.button;
        if (mouseClick === 2 || mouseClick === 1 || this.invalidSpace) {
          return false;
        }
        return true;
      },
      snapTolerance: 1,
      ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
    });
    this.mapObj.map.addInteraction(this.draw);

    this.modify = new Modify({
      source: src,
      condition: () => !this.invalidSpace,
      ...(this.isRegularShape && { insertVertexCondition: never }),
      snapToPointer: true
    });
    this.mapObj.map.addInteraction(this.modify);

    this.modify.on('modifystart', (e: $TSFixMe) => {
      const geom = e.features.getArray()[0]?.getGeometry();
      this.lastGeometry = geom.clone();
    });

    this.draw.on('drawstart', (e: $TSFixMe) => {
      this.modify.setActive(false);
      this.isDrawActive = true;
      const geom = e.feature.getGeometry();
      if (geom.getType() === GEOMETRY_TYPE_STRING.POINT) return;

      if (!this.mapObj.isBlueprintMap) {
        if (!this.lotFeature && this.toolId !== TOOLS_ID.ADD_CIRCLE) this.draw.abortDrawing();
      }
    });

    this.draw.on('drawend', this.handleDrawEnd);
    this.modify.on('modifyend', this.handleChangedLayers);

    this.domElements = {
      container: document.getElementById('add-feature-container'),
      select: document.getElementById('add-feature-select')
    };
    this.mapObj.map.on('click', this.handleMapClick);
    document.addEventListener('keydown', this.handleKeyDownEvents);
    document.addEventListener('keyup', this.handleKeyUpEvents);
  }

  highlightFeatureOnHover = (e: $TSFixMe) => {
    if (!this.mapObj.isBlueprintMap) {
      const { edgeSnapping, vertexSnapping } = useToolData.getState();

      const [extractLotFeature] = this.mapObj.getParcelFeaturesAtCoordinate(e.coordinate, true);
      if (extractLotFeature) this.lotFeature = extractLotFeature;

      if (extractLotFeature && (edgeSnapping || vertexSnapping)) {
        // To allow drawing from slightly outside the parcel while snapping is enabled
        const feature = new GeoJSON().writeFeatureObject(extractLotFeature, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        });
        // @ts-expect-error
        const turfbuffer = buffer(feature, 5, { units: 'feet' });
        this.lotFeature = new GeoJSON().readFeature(turfbuffer, {
          featureProjection: 'EPSG:3857'
        });
      }
    }
    this.invalidSpace = !this.mapObj.coordsExistsInParcel(
      e.coordinate,
      this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
    );
    changeMapCursor(this.invalidSpace, 'not-allowed');
  };

  handleChangedLayers = (e: $TSFixMe) => {
    const features = e.features.getArray();
    const feature = features.length && features[0];
    if (feature) {
      const layerId = feature.get('layerId');
      if (this.invalidSpace) {
        feature.setGeometry(this.lastGeometry.clone());
        return;
      }
      this.lastGeometry = feature.getGeometry().clone();

      // Push layer in tracker
      layerTracker.push(this.mapObj.getLayerName(layerId), layerId);

      this.notifyObservers(TOOL_EVENT.FEATURE_ADDED);
    }
  };

  handleKeyDownEvents = (event: $TSFixMe) => {
    if (event.stopPropagation) event.stopPropagation();

    const KeyID = event.keyCode;
    if (KeyID === 16 && this.isRegularShape) {
      this.isShiftKeyPressed = true;
    }

    if (this.isDrawActive && (event.ctrlKey || event.metaKey) && KeyID === 90 && this.toolId !== TOOLS_ID.ADD_CIRCLE) {
      event.stopImmediatePropagation();
      this.draw.removeLastPoint();
    }
    if (KeyID === 27) {
      this.draw.abortDrawing();
    }
  };

  handleKeyUpEvents = (event: $TSFixMe) => {
    if (event.stopPropagation) event.stopPropagation();
    const KeyID = event.keyCode;
    if (KeyID === 16 && this.isRegularShape) {
      this.isShiftKeyPressed = false;
    }
  };

  handleDrawEnd = (e: $TSFixMe) => {
    this.modify.setActive(true);
    this.isDrawActive = false;
    if (this.geomType !== GEOMETRY_TYPE_STRING.POINT) {
      const geom = e.feature.getGeometry();

      const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
        geom,
        boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
      });

      if (is_out_of_extent) {
        this.layer.setSource(new VectorSource({ wrapX: false }));
        return message.error('Oops! The feature cannot be saved because it crossed the boundaries.');
      }
    }
    this.currentFeature = e.feature;
    this.addFeatureToLayer(e);
    this.temporaryFeature = null;
    return null;
  };

  openNumericalPopup = (feature: $TSFixMe) => {
    const { id, layerId, pageX, pageY } = getNumericalPopupInfo(this.mapObj.map, feature);
    this.notifyObservers(TOOL_EVENT.NUMERICAL_FEATURE_POPUP_TOGGLE, { pageX, pageY, id, layerId, open: true });
  };

  removeEmptyFeatures = (layer: $TSFixMe) => {
    const source = layer.getSource();
    const features = source.getFeatures();
    features.forEach((feature: $TSFixMe) => {
      const geometry = feature.getGeometry();
      if (!geometry || geometry.getCoordinates().length === 0) {
        source.removeFeature(feature);
      }
    });
  };

  async addFeatureToLayer(e: $TSFixMe) {
    const targetLayer = this.mapObj.getLayerById(this.layerId);
    if (targetLayer) {
      const { avoidOverlap, overrideOverlap } = useToolData.getState();
      let modifiedLayers: string[] = [];

      this.removeEmptyFeatures(targetLayer);
      const id = targetLayer.getSource().getFeatures().length + 1;
      this.currentFeature.setProperties({ layerId: this.layerId, id });
      targetLayer.getSource().addFeature(this.currentFeature);
      this.layer.setSource(new VectorSource({ wrapX: false }));

      if (
        (this.geomType === GEOMETRY_TYPE_STRING.POLYGON || this.isRegularShape) &&
        (avoidOverlap || overrideOverlap)
      ) {
        modifiedLayers = await applyOverlapSettings(this.currentFeature, this.layerId, overrideOverlap);
      }

      // Show numerical feature popup
      if (isNumericalLayer(targetLayer)) this.openNumericalPopup(e.feature);

      if (modifiedLayers.length) {
        modifiedLayers.forEach((layerId: string) => layerTracker.push(this.mapObj.getLayerName(layerId), layerId));
      } else {
        layerTracker.push(this.mapObj.getLayerName(this.layerId), this.layerId);
      }

      if (!isNumericalLayer(targetLayer)) this.notifyObservers(TOOL_EVENT.FEATURE_ADDED);

      this.currentFeature = null;
      this.lotFeature = null;
    }
  }

  hasAnyFeatureWithoutTargetLayer() {
    const src = this.layer.getSource();
    const features = src.getFeatures();
    return features && features.length;
  }

  createCircle = (coordinates: $TSFixMe, geometry: $TSFixMe) => {
    let result;
    if (this.isShiftKeyPressed) {
      // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
      result = createRegularPolygon(100)(coordinates, geometry);
      if (!this.temporaryFeature) {
        this.temporaryFeature = new Feature(result);
      } else {
        this.temporaryFeature.setGeometry(result);
      }
    } else {
      const start = coordinates[0];
      const end = coordinates[1];
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];

      const radius = Math.sqrt(dx * dx + dy * dy);
      const center = [start[0] + dx / 2, start[1] + dy / 2];
      const circle = new Circle(center, radius / 2);
      const polygon = fromCircle(circle, 100);
      polygon.scale(dx / radius, dy / radius);

      if (!geometry) {
        result = polygon;
      } else {
        result = geometry.setCoordinates(polygon.getCoordinates());
      }
    }

    return result;
  };

  drawCurve = (coordinates: $TSFixMe, geometry: $TSFixMe) => {
    const isPolygon = this.geometryType === GEOMETRY_TYPE_ENUM.POLYGON;
    if (!geometry) {
      geometry = isPolygon ? new Polygon([]) : new LineString([]);
    }

    if (isPolygon) {
      const extendedCoordinates = bezier(coordinates.concat(coordinates, coordinates, [coordinates[0]]), {
        resolution: 30000
      });
      const length = extendedCoordinates.length / 3;
      const section = extendedCoordinates.slice(length, length * 2);
      geometry.setCoordinates([section.concat([section[0]])]);
    } else {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      geometry.setCoordinates(bezier(coordinates));
    }

    return geometry;
  };

  createBox = (coordinates: $TSFixMe, geometry: $TSFixMe) => {
    if (this.isShiftKeyPressed) {
      // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
      const result = createRegularPolygon(4)(coordinates, geometry);
      if (!this.temporaryFeature) {
        this.temporaryFeature = new Feature(result);
      } else {
        this.temporaryFeature.setGeometry(result);
      }
      return result;
    } else {
      // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
      return createBox()(coordinates, geometry);
    }
  };

  handleMapClick = () => {
    if (this.draw.getActive() && this.temporaryFeature) {
      this.handleDrawEnd({ feature: this.temporaryFeature });
    }
  };

  off() {
    if (this.draw) {
      this.mapObj.map.removeInteraction(this.draw);
      this.draw.un('drawend', this.handleDrawEnd);
    }
    this.lotFeature = null;
    this.baseLayerProps = null;
    if (this.modify) {
      this.mapObj.map.removeInteraction(this.modify);
    }
    if (this.layer) {
      this.mapObj.removeLayer(this.layer);
    }
    document.removeEventListener('keydown', this.handleKeyDownEvents);
    document.removeEventListener('keyup', this.handleKeyUpEvents);
    this.mapObj.map.un('click', this.handleMapClick);
    this.mapObj.map.un('pointermove', this.highlightFeatureOnHover);
    this.areaStyleCache = [];
    this.isDrawActive = false;
    this.temporaryFeature = null;
  }
}

export default AddFeatures;
