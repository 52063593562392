import React from "react";

const CopyAndMoveIcon = ({ fill = "#666666" }) => {
  return (
    <svg
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 9.24754C20.1174 9.24754 23.2525 12.3826 23.2525 16.25C23.2525 20.1174 20.1174 23.2525 16.25 23.2525C12.3826 23.2525 9.24754 20.1174 9.24754 16.25C9.24754 12.3826 12.3826 9.24754 16.25 9.24754ZM2.25051 4.14919L2.25 15.375C2.25 17.6946 4.05519 19.5926 6.33734 19.7407L6.625 19.75L8.22547 19.7502C8.49848 20.3752 8.84238 20.9622 9.247 21.501L5.75 21.5C2.85051 21.5 0.5 19.1495 0.5 16.25V6.625C0.5 5.48187 1.2307 4.5094 2.25051 4.14919ZM15.8969 11.0791L15.7635 11.155L15.6423 11.2563L15.541 11.3775C15.3343 11.6759 15.3343 12.0741 15.541 12.3725L15.6423 12.4937L18.5215 15.3732L11.875 15.375L11.7177 15.3891C11.3606 15.4539 11.0789 15.7356 11.0141 16.0927L11 16.25L11.0141 16.4073C11.0789 16.7644 11.3606 17.0461 11.7177 17.1109L11.875 17.125L18.525 17.1232L15.6423 20.0063L15.541 20.1275C15.3048 20.4685 15.3385 20.94 15.6423 21.2437C15.946 21.5475 16.4175 21.5812 16.7585 21.345L16.8797 21.2437L21.3066 16.8121L21.3713 16.7245L21.4449 16.5843L21.4904 16.4395L21.5076 16.3276V16.1726L21.4905 16.061L21.4452 15.9165L21.3719 15.7762L21.3066 15.6879L16.8797 11.2563L16.7585 11.155C16.5028 10.9779 16.1736 10.9525 15.8969 11.0791ZM15.375 0.5C16.8247 0.5 18 1.67525 18 3.125L18.0013 7.67279C17.4355 7.55787 16.8498 7.49754 16.25 7.49754V3.125C16.25 2.64175 15.8582 2.25 15.375 2.25H6.625C6.14175 2.25 5.75 2.64175 5.75 3.125V15.375C5.75 15.8582 6.14175 16.25 6.625 16.25H7.49754C7.49754 16.8498 7.55787 17.4355 7.67279 18.0013L6.625 18C5.17525 18 4 16.8247 4 15.375V3.125C4 1.67525 5.17525 0.5 6.625 0.5H15.375Z"
        fill={fill}
      />
    </svg>
  );
};

export default CopyAndMoveIcon;
