export const WEEK_START = 1; // Monday
export const TEXT_DETECTION_ENDPOINT = `${process.env.NEXT_PUBLIC_BASE_URL}/third-party/extract_text_from_image/`;
export const USER_OS = {
  WIN: 'Windows',
  MAC: 'Mac OS',
  LINUX: 'Linux',
  IOS: 'iOS',
  ANDROID: 'Android',
  UNKNOWN: 'Unknown'
};
