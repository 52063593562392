import { GEOMETRY_TYPES, UNSET_PROPERTIES } from 'woodpecker';
import { drawStyle, labelStyle, selectStyle } from '../../../hooks/tools/helpers/styles';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import Draw from 'ol/interaction/Draw';
import Select from 'ol/interaction/Select';
import { formatArea } from '../../../hooks/tools/helpers';
import Polygon from 'ol/geom/Polygon';
import * as turf from '@turf/turf';
import { isOutOfExtent } from '../../../helpers/helpers';
import { canAutoFinishDrawing, drawHandler } from '../../../helpers/interactionUtils';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { LinearRing, MultiPolygon } from 'ol/geom';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';

class AddRing extends ToolAbstract {
  private mapObj: MapBase;
  private layer: any;
  private draw: Draw | null;
  private select: Select | null;
  private feature: Feature | null;
  private lastOKCoord: Coordinate | null;
  private lastFinalCoordinates: Coordinate | null;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.select = null;
    this.feature = null;
    this.lastOKCoord = null;
    this.lastFinalCoordinates = null;
  }

  init(id: string) {
    this.layer = this.mapObj.getLayerById(id);
    this.select = new Select({
      layers: [this.layer],
      style: selectStyle()
    });

    this.select.setActive(false);

    const options = {
      type: GEOMETRY_TYPES.POLYGON,
      dragVertexDelay: 0,
      clickTolerance: 12,
      features: this.select.getFeatures(),
      style: (feat: any) => {
        if (feat?.getGeometry().getType() === GEOMETRY_TYPES.LINESTRING) {
          return null;
        }
        return this.styleFunction(feat);
      },
      geometryFunction: this.geometryFunction,
      finishCondition: canAutoFinishDrawing,
      condition: (e: any) =>
        drawHandler(e, this.draw as Draw, { avoidDrawingCallback: () => isOutOfExtent(e, this.mapObj.map) })
    };

    this.draw = new Draw(options as any);
    this.mapObj.map?.addInteraction(this.select);
    this.mapObj.map?.addInteraction(this.draw);
    this.draw?.on('drawend', this.onDrawEnd);
    this.draw?.on('drawstart', this.onDrawStart);
    window.addEventListener('keydown', this.keyDownHandler);
  }

  styleFunction = (feature: Feature) => {
    let styles = [drawStyle()];
    const { scale, dpi } = globalStore.AppStore.worksheetParams;
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.POLYGON && scale !== null) {
      const label = formatArea(geometry, dpi, scale);
      const _labelStyle = labelStyle.clone();
      _labelStyle.setGeometry(geometry);
      _labelStyle.getText().setText(label);
      styles.push(_labelStyle);
    }
    return styles;
  };

  keyDownHandler = (e: KeyboardEvent) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  onDrawEnd = (e: any) => {
    const hole = e.feature;
    const holeGeom = hole.getGeometry();
    if (!holeGeom) {
      return;
    }
    let intersectFeature = false;
    const poly = this.feature?.getGeometry() as any;
    const coords = holeGeom.getCoordinates()[0];

    if (!coords) {
      this.onAbortDrawing();
      return;
    }

    for (const coord of coords) {
      if (!poly?.intersectsCoordinate(coord)) {
        intersectFeature = true;
        break;
      }
    }

    const linearRing = new LinearRing(coords);
    if (poly?.getType() == 'Polygon') {
      poly.appendLinearRing(linearRing);
    } else if (poly?.getType() == 'MultiPolygon') {
      const newGeom = new MultiPolygon([]);
      for (let i = 0, pi; (pi = poly.getPolygon(i)); i++) {
        pi.appendLinearRing(new LinearRing(linearRing as any));
        newGeom.appendPolygon(pi);
      }
      this.feature?.setGeometry(newGeom);
    }
    UNSET_PROPERTIES.forEach(property => {
      this.feature?.unset(property, false);
    });
    undoRedoPush();
    this.select?.getFeatures().clear();
    this.select?.setActive(false);
    this.feature = null;
    this.lastOKCoord = null;
    this.lastFinalCoordinates = [];
  };

  onDrawStart = (e: any) => {
    this.layer.getSource().forEachFeatureIntersectingExtent(e.feature.getGeometry().getExtent(), (feat: any) => {
      this.feature = feat;
    });
    if (!this.feature) {
      this.onAbortDrawing();
      e.target.abortDrawing();
      return;
    }

    this.select?.setActive(true);
    this.draw?.setActive(true);
  };

  geometryFunction = (coordinates: any, geometry: any) => {
    /**
     * It first check if the new coordinate lies inside the select polygon
     */
    const coord = coordinates[0].pop();
    if (!this.feature || this.feature?.getGeometry()?.intersectsCoordinate(coord)) {
      this.lastOKCoord = [coord[0], coord[1]];
    }
    coordinates[0].push([this.lastOKCoord![0], this.lastOKCoord![1]]);

    if (geometry) {
      geometry.setCoordinates([coordinates[0].concat([coordinates[0][0]])]);
    } else {
      geometry = new Polygon(coordinates);
    }

    const points = coordinates[0];
    if (points && points.length > 2 && this.feature) {
      const holePoly = new Polygon(geometry.getCoordinates() as any);
      const lineSegments = turf.polygonToLine(turf.polygon(holePoly.getCoordinates()));
      const currFeatureLines = turf.polygonToLine(
        // @ts-ignore: Unreachable code error
        turf.polygon(this.feature?.getGeometry()?.getCoordinates())
      );
      const intersection = turf.lineIntersect(lineSegments, currFeatureLines);
      if (intersection?.features?.length < 2) {
        this.lastFinalCoordinates = geometry.getCoordinates();
      }
      geometry?.setCoordinates(this.lastFinalCoordinates);
    }
    if (!geometry.getCoordinates().length) {
      geometry?.setCoordinates([]);
      this.onAbortDrawing();
    }
    return geometry;
  };

  onAbortDrawing = () => {
    this.lastFinalCoordinates = [];
    this.select?.getFeatures().clear();
    this.select?.setActive(false);
    this.feature = null;
    this.lastOKCoord = null;
    this.lastFinalCoordinates = [];
    this.draw?.abortDrawing();
  };

  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.mapObj.map?.removeInteraction(this.select as Select);
    this.draw && this.draw.un('drawstart', this.onDrawStart);
    this.draw && this.draw.un('drawend', this.onDrawEnd);
    window.removeEventListener('keydown', this.keyDownHandler);
  }
}

export default AddRing;
