import React from "react";

const MoveIcon = ({ fill = "#666666" }) => {
  return (
    <svg
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_862_16986)">
        <path
          d="M20.9999 12.8334V9.33337L25.6666 14L20.9999 18.6667V15.1667H15.1666V21H18.6666L13.9999 25.6667L9.33325 21H12.8333V15.1667H6.99992V18.6667L2.33325 14L6.99992 9.33337V12.8334H12.8333V7.00004H9.33325L13.9999 2.33337L18.6666 7.00004H15.1666V12.8334H20.9999Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_862_16986">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoveIcon;
