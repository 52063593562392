import React from "react";

const AbstractIcon = (props: any) => {
  const { strokeColor = "#000000" } = props;
  return (
    <svg
      width="1.5rem"
      height="3rem"
      viewBox="0 0 24 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.167L14.7124 23.0213L22.3923 30.167L12 27.4583L1.6077 30.167L9.28761 23.0213L12 13.167Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default AbstractIcon;
