import MapBase from '../../mapLayer/mapBase';
import FlipTool from '../tools/FlipGeometry';
import { FlipDirEnum } from '../../../constants';

class Flip {
  mapObj: MapBase;
  flipTool: FlipTool;

  constructor(mapObj: any) {
    this.mapObj = mapObj;
    this.flipTool = new FlipTool(mapObj);
  }
}

class FlipHorizontally extends Flip {
  execute() {
    this.flipTool.on({ dir: FlipDirEnum.HORIZONTAL });
    this.flipTool.off();
  }
}

class FlipVertically extends Flip {
  execute() {
    this.flipTool.on({ dir: FlipDirEnum.VERTICAL });
    this.flipTool.off();
  }
}
export { FlipHorizontally, FlipVertically };
