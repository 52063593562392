import { styled } from '@attentive-platform/stem-ui';
import { css } from '@emotion/css';

import { StyledComponent } from '@emotion/styled';

export const ActionMenuContainer: StyledComponent<{ top: number; left: number }> = styled('div')<{
  top: number;
  left: number;
}>(({ top, left }) => ({
  position: 'absolute',
  top,
  left
}));

export const StyledCode: any = styled('code')(() => ({
  backgroundColor: '#ebebeb !important',
  color: '#000 !important',
  padding: '4px'
}));

export const StyledRotateLabelContainer: any = styled('div')(() => ({
  width: '150px',
  display: 'flex',
  gap: '4px',
  padding: '2px',
  '.action-icon': {
    width: '15%'
  },
  '.action-title': {
    width: '55%'
  },
  '.action-shortcut': {
    marginLeft: 'auto',
    marginRight: 0,
    width: '30%'
  }
}));

export const StyledRotateButton: any = styled('button')(() => ({
  background: 'transparent',
  border: 'none',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer'
}));

export const selectMenuContainer = css`
  background-color: white;
  border-radius: 0.375rem;
  padding: 4px 14.451px 6px 14.451px;
  box-shadow: 6px 6px 18px 0px #00000014;
  display: flex;
  gap: 1rem;
`;

export const layersDropdownContainer = css`
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 6px 6px 18px 0px #00000014;
  margin-top: 0.5rem;
`;

export const layersSelect = css`
  max-height: 203px;
  overflow-y: auto;
  padding: 0px 8px 5px 8px;
`;

export const iconContainer = css`
  display: flex;
  gap: 0.5rem;
`;

export const iconButton = css`
  height: 2.25rem;
  cursor: pointer;
  width: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const disabled = css`
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
`;

export const selectedStyle = css`
  background-color: #e8ebfd;
  border-radius: 0.375rem;
`;
