import { MapBrowserEvent } from 'ol';
import { MOUSE_CLICK_EVENT } from '../constants';

export const detectRightDoubleClick = (() => {
  // Stores last right-click time per target
  const clickTimestamps = new WeakMap<EventTarget, number>();
  const DOUBLE_CLICK_THRESHOLD = 300;

  return (e: MapBrowserEvent<UIEvent>): boolean => {
    const originalEvent = e.originalEvent as MouseEvent;

    if (originalEvent.button !== MOUSE_CLICK_EVENT.RIGHT_CLICK) return false;

    // Prevent context menu (MacOS workaround)
    e.preventDefault();

    const target = originalEvent.target as EventTarget;
    const lastClickTime = clickTimestamps.get(target) || 0;
    const currentTime = Date.now();

    // Detect double-click
    if (currentTime - lastClickTime <= DOUBLE_CLICK_THRESHOLD) {
      clickTimestamps.delete(target); // Reset after detection
      return true;
    }

    // Store current click time
    clickTimestamps.set(target, currentTime);
    return false;
  };
})();
