import React from "react";

const SVGline = (props: any) => {
  const { strokeColor = "#000000" } = props;
  return (
    <svg
      width="1.5rem"
      height="0.375rem"
      viewBox="0 0 24 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="4.75"
        width="4.5"
        height="24"
        transform="rotate(-90 0 4.75)"
        fill={strokeColor}
      />
    </svg>
  );
};

export default SVGline;
