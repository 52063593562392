import { getGeom, getCoords } from '@turf/invariant';
import { polygon as turfPolygon, lineString, featureCollection } from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import lineOffset from '@turf/line-offset';
import lineToPolygon from '@turf/line-to-polygon';
import difference from '@turf/difference';
import simplify from '@turf/simplify';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Draw } from 'ol/interaction';
import { GEOMETRY_TYPES, UNSET_PROPERTIES } from 'woodpecker';
import { drawStyle } from '../../../helpers/helpers';
import { canAutoFinishDrawing, drawHandler } from '../../../helpers/interactionUtils';
import { GEO_JSON, generateUniqueID, isPointWithinPolygon } from 'macaw';
import { undoRedoPush } from '../../mapLayer/mapInit';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import MapBase from '../../mapLayer/mapBase';
import { Feature } from 'ol';

const { LINESTRING, POLYGON, MULTI_POLYGON } = GEOMETRY_TYPES;

class SplitPolygon extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null;
  private lineLayer: any;
  private targetPolygon: any;
  private selected_layer_id: string;
  private snap: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.lineLayer = null;
    this.targetPolygon = null;
    this.selected_layer_id = '';
  }

  init(id: string) {
    this.off();
    this.selected_layer_id = id;
    let sourceDrawnLines = new VectorSource({ wrapX: false });
    this.lineLayer = new VectorLayer({
      source: sourceDrawnLines
    });
    this.mapObj.map?.addLayer(this.lineLayer);

    this.draw = new Draw({
      source: sourceDrawnLines,
      //@ts-ignore
      type: LINESTRING,
      style: drawStyle(id),
      dragVertexDelay: 0,
      snapTolerance: 1,
      finishCondition: canAutoFinishDrawing,
      condition: (e: any) => drawHandler(e, this.draw as Draw)
    });

    this.mapObj.map?.addInteraction(this.draw);
    this.snap = this.mapObj.getSnap();
    this.snap.forEach((snap: any) => {
      this.mapObj.map?.addInteraction(snap);
    });
    this.draw.on('drawend', this.drawEnd);
    window.addEventListener('keydown', this.handleKeyDawn);
  }

  handleKeyDawn = (e: any) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  drawEnd = (e: any) => {
    const drawnFeature = e.feature;
    const drawnExtent = drawnFeature.getGeometry().getExtent();
    const type = drawnFeature.getGeometry().getType();
    //    globalStore.AppStore.setLoaderState(true);
    setTimeout(() => {
      const layerPoly = this.mapObj.getLayerById(this.selected_layer_id);
      if (layerPoly) {
        const sourcePoly = layerPoly.getSource();
        const featuresInExtent = sourcePoly.getFeaturesInExtent(drawnExtent);
        const drawnGeoJSON = GEO_JSON.writeFeatureObject(drawnFeature);
        const drawnGeometry = getGeom(drawnGeoJSON as any);
        if (type == GEOMETRY_TYPES.LINESTRING) {
          featuresInExtent.forEach((feature: any) => {
            const featureGeo = GEO_JSON.writeFeatureObject(feature);
            const fcopy = Object.assign({}, featureGeo);
            const polygon = getGeom(featureGeo as any);
            try {
              const cutPolygon = this.polygonCut(polygon, drawnGeometry);

              if (cutPolygon != null) {
                const features = GEO_JSON.readFeatures(cutPolygon);
                features.forEach(feature => {
                  UNSET_PROPERTIES.forEach(property => {
                    feature.unset(property, false);
                  });
                });
                sourcePoly.addFeatures(features);
                sourcePoly.removeFeature(feature);
              }
            } catch (err) {
              //   setExtra("feature", JSON.stringify(fcopy));
              //   setExtra("drawn", JSON.stringify(drawnGeometry));
              //   setExtra("Request ID", localStorage.getItem("job_id"));
              //   captureException(err);
            }
          });
        }
        sourcePoly.getFeatures().forEach((feat: Feature, index: number) => {
          feat.setId(generateUniqueID() + index);
        });
        undoRedoPush();
      }
      //   globalStore.AppStore.setLoaderState(false);
      this.mapObj.map?.removeLayer(this.lineLayer);
      //   globalStore.AppStore.setUpdateMapLegend();
    }, 10);
  };

  polygonCut(polygon: any, line: any) {
    const THICK_LINE_UNITS = 'meters';
    const THICK_LINE_WIDTH = 0.00001; // Need to reduce because when number of splits performs on single feature that will increases the actual size of the polygon
    let i: any, j, id, intersectPoints, lineCoords, forCut, forSelect;
    let thickLineString, thickLinePolygon, clipped: any, polyg, intersect;
    let polyCoords = [];
    let cutPolyGeoms = [];
    let cutFeatures: Array<any> = [];
    let offsetLine = [];
    let retVal = null;
    let idPrefix = 'cut_';

    if ((polygon.type != POLYGON && polygon.type != MULTI_POLYGON) || line.type != LINESTRING) {
      return retVal;
    }

    if (typeof idPrefix === 'undefined') {
      idPrefix = '';
    }

    intersectPoints = lineIntersect(polygon, line);
    if (intersectPoints.features.length == 0) {
      return retVal;
    }

    lineCoords = getCoords(line);
    if (isPointWithinPolygon(lineCoords[0], polygon) || isPointWithinPolygon(lineCoords.at(-1), polygon)) {
      return retVal;
    }

    offsetLine[0] = lineOffset(line, THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS
    });
    offsetLine[1] = lineOffset(line, -THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS
    });

    for (i = 0; i <= 1; i++) {
      forCut = i;
      forSelect = (i + 1) % 2;
      polyCoords = [];
      for (j = 0; j < line.coordinates.length; j++) {
        polyCoords.push(line.coordinates[j]);
      }
      for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
        polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
      }
      polyCoords.push(line.coordinates[0]);

      thickLineString = lineString(polyCoords);
      thickLinePolygon = lineToPolygon(thickLineString);
      polygon = simplify(polygon, { tolerance: 1e-9 }); // simplify due to topological exception
      thickLinePolygon = simplify(thickLinePolygon, { tolerance: 1e-9 }); // simplify due to topological exception
      clipped = difference(polygon, thickLinePolygon);

      cutPolyGeoms = [];
      for (j = 0; j < clipped.geometry.coordinates.length; j++) {
        polyg = turfPolygon(clipped.geometry.coordinates[j]);
        intersect = lineIntersect(polyg, offsetLine[forSelect]);
        if (intersect.features.length > 0) {
          cutPolyGeoms.push(polyg.geometry.coordinates);
        }
      }

      cutPolyGeoms.forEach(function (geometry, index) {
        id = idPrefix + (i + 1) + '.' + (index + 1);
        cutFeatures.push(turfPolygon(geometry));
      });
    }
    if (cutFeatures.length > 0) {
      retVal = featureCollection(cutFeatures);
    }

    return retVal;
  }

  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.lineLayer && this.mapObj.map?.removeLayer(this.lineLayer);
    window.removeEventListener('keydown', this.handleKeyDawn);
  }
}

export default SplitPolygon;
