import React from "react";

const TypicalIcon = ({ width = "1.25rem", height = "1.25rem", ...props }) => {
  const { strokeColor = "#000000" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0006 15.2167L4.12271 18.5069L5.43548 11.9L0.48999 7.32658L7.17919 6.53346L10.0006 0.416748L12.8219 6.53346L19.5111 7.32658L14.5657 11.9L15.8784 18.5069L10.0006 15.2167Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default TypicalIcon;
