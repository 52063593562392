import React from "react";

const SVGpoint = (props: any) => {
  const { strokeColor = "#000000" } = props;
  return (
    <svg
      width="1.5rem"
      height="0.75rem"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="12.5"
        width="12"
        height="12"
        rx="6"
        transform="rotate(-90 0 12.5)"
        fill={strokeColor}
      />
    </svg>
  );
};

export default SVGpoint;
