import React from "react";

const RotateAntiClockwiseIcon = ({ fill = "#666666" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_627_959)">
      <path
        d="M2.44259 6.71369L1.06576 5.33611L0 6.40187L3.19578 9.5969L6.39156 6.40187L5.3258 5.33611L3.94897 6.71369V4.76595C3.94897 4.16667 4.18703 3.59194 4.61078 3.16819C5.03453 2.74444 5.60927 2.50638 6.20854 2.50638H9.2213V1H6.20854C5.20975 1 4.25187 1.39677 3.54561 2.10302C2.83936 2.80927 2.44259 3.76716 2.44259 4.76595V6.71369ZM7.71492 5.51914C7.51516 5.51914 7.32358 5.59849 7.18233 5.73974C7.04108 5.88099 6.96173 6.07257 6.96173 6.27233V13.8042C6.96173 14.004 7.04108 14.1956 7.18233 14.3368C7.32358 14.4781 7.51516 14.5574 7.71492 14.5574H15.2468C15.4466 14.5574 15.6381 14.4781 15.7794 14.3368C15.9206 14.1956 16 14.004 16 13.8042V6.27233C16 6.07257 15.9206 5.88099 15.7794 5.73974C15.6381 5.59849 15.4466 5.51914 15.2468 5.51914H7.71492ZM8.46811 7.02551H14.4936V13.051H8.46811V7.02551Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_627_959">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="matrix(-1 0 0 1 16 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RotateAntiClockwiseIcon;
