import React from "react";

const DeleteIcon = ({ fill = "#666666" }) => {
  return (
    <svg
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1711_35730)">
        <path
          d="M17.0547 6H22.0547V8H20.0547V21C20.0547 21.2652 19.9493 21.5196 19.7618 21.7071C19.5743 21.8946 19.3199 22 19.0547 22H5.05469C4.78947 22 4.53512 21.8946 4.34758 21.7071C4.16004 21.5196 4.05469 21.2652 4.05469 21V8H2.05469V6H7.05469V3C7.05469 2.73478 7.16004 2.48043 7.34758 2.29289C7.53512 2.10536 7.78947 2 8.05469 2H16.0547C16.3199 2 16.5743 2.10536 16.7618 2.29289C16.9493 2.48043 17.0547 2.73478 17.0547 3V6ZM18.0547 8H6.05469V20H18.0547V8ZM9.05469 11H11.0547V17H9.05469V11ZM13.0547 11H15.0547V17H13.0547V11ZM9.05469 4V6H15.0547V4H9.05469Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1711_35730">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.0546875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteIcon;
