import React from "react";

const DeleteRingIcon = ({ fill = "#666666" }) => {
  return (
    <svg
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1252_26334)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 14C0.5 21.7315 6.7825 28 14.5 28C22.2315 28 28.5 21.7175 28.5 14C28.5 6.2685 22.2175 0 14.5 0C6.7685 0 0.5 6.2825 0.5 14ZM2.5 14C2.5 8.21275 8.7005 2 14.5 2C20.2873 2 26.5 8.2005 26.5 14C26.5 19.7873 20.2995 26 14.5 26C8.71275 26 2.5 19.7995 2.5 14Z"
          fill={fill}
        />
        <g clipPath="url(#clip1_1252_26334)">
          <path
            d="M18.3333 9.99992H21.6666V11.3333H20.3333V19.9999C20.3333 20.1767 20.2631 20.3463 20.1381 20.4713C20.013 20.5963 19.8435 20.6666 19.6666 20.6666H10.3333C10.1565 20.6666 9.98693 20.5963 9.86191 20.4713C9.73688 20.3463 9.66665 20.1767 9.66665 19.9999V11.3333H8.33331V9.99992H11.6666V7.99992C11.6666 7.82311 11.7369 7.65354 11.8619 7.52851C11.9869 7.40349 12.1565 7.33325 12.3333 7.33325H17.6666C17.8435 7.33325 18.013 7.40349 18.1381 7.52851C18.2631 7.65354 18.3333 7.82311 18.3333 7.99992V9.99992ZM19 11.3333H11V19.3333H19V11.3333ZM13 13.3333H14.3333V17.3333H13V13.3333ZM15.6666 13.3333H17V17.3333H15.6666V13.3333ZM13 8.66659V9.99992H17V8.66659H13Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1252_26334">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1_1252_26334">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(7 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteRingIcon;
