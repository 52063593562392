import { GEOMETRY_TYPE, Tool as ToolInterface } from 'woodpecker';
import RotateClockwiseIcon from 'ui/src/Assets/RotateClockwiseIcon';
import RotateAntiClockwiseIcon from 'ui/src/Assets/RotateAntiClockwiseIcon';
import FlipVerticalIcon from 'ui/src/Assets/FlipVertical';
import FlipHorizontalIcon from 'ui/src/Assets/FlipHorizontal';
import DrawPolygon from '../tools/AddPolygon';
import Tool from '../toolClass';
import Action from '../actionClass';
import Undo from '../actions/Undo';
import Redo from '../actions/Redo';
import DrawPoint from '../tools/AddPoint';
import AddRectangle from '../tools/AddRectangle';
import AddRing from '../tools/AddRing';
import SelectTool from '../tools/SelectTool';
import MoveTool from '../tools/Move';
import CopyAndMoveTool from '../tools/CopyAndMove';
import DimensionTool from '../tools/DimensionTool';
import Delete from '../actions/Delete';
import Merge from '../actions/Merge';
import ReshapePolygon from '../tools/ReshapePolygon';
import AddPolyLine from '../tools/AddPolyline';
import SplitLine from '../tools/SplitLine';
import SplitPolygon from '../tools/SplitPolygon';
import AddAbstract from '../tools/AddAbstract';
import AddSegment from '../tools/AddSegment';
import TextDetection from '../tools/TextDetection';
import LineSnippingTool from '../tools/LineSnippingTool';
import AddCircle from '../tools/AddCircle';
import AddRectangularHole from '../tools/AddRectangularHole';
import AddRectangularFill from '../tools/AddRectangularFill';
import AddCircularFill from '../tools/AddCircularFill';
import AddCicularHole from '../tools/AddCircularHole';
import DeleteRing from '../tools/DeleteRing';
import AddFillRing from '../tools/AddFillRing';
import PolygonToLine from '../tools/PolygontoLine';
import AddCurvedPolygon from '../tools/AddCurvePolygon';
import AddCurvedLine from '../tools/AddCurvedPolyLine';
import HighlightTool from '../tools/HighlightTool';
import ModifySegment from '../tools/ModifySegment';
import PolylineFilling from '../tools/PolylineFilling';
import PolygonFilling from '../tools/PolygonFilling';
import PolygonSnapping from '../tools/PolygonSnapping';
import PolylineSnapping from '../tools/PolylineSnapping';
import ModifyVertex from '../tools/ModifyVertex';
import LineScale from '../tools/LineScale';
import AddSnapping from '../tools/AddSnapping';
import MultiSelectTool from '../tools/MultiSelectTool';
import AddComment from '../tools/AddComment';
import CopyCoordinatesTool from '../tools/CopyCoordinates';
import Reclassify from '../actions/Reclassify';
import ShowVertex from '../tools/ShowVertex';
import ArrowTool from '../tools/ArrowTool';
import TextBox from '../tools/TextBox';
import TaggingTool from '../tools/Tagging';
import AddCommentBPT from '../tools/AddCommentBPT';
import CopySegment from '../tools/CopySegment';
import { HandDrawingTool } from '../tools/HandDrawingTool';
import FlipTool from '../tools/FlipGeometry';
import DragRotate from '../tools/DragRotate';
import RotateTool from '../tools/RotateGeometry';
import { ClockwiseRotation, AntiClockwiseRotation } from '../actions/PageRotation';
import AddLineRectangle from '../tools/AddLineRectangle';
import AddLineCircle from '../tools/AddLineCircle';
import { RotateAntiClockwise, RotateClockwise } from '../actions/Rotate';
import { FlipHorizontally, FlipVertically } from '../actions/Flip';

const { POLYGON, POINT, LINE, ABSTRACT, TYPICAL } = GEOMETRY_TYPE;

/*Tool ids */
export const TOOL_TYPE = {
  ADD_POLYGON: 'add_polygon',
  PAN: 'pan',
  ADD_POINT: 'count',
  ADD_POLYLINE: 'add_polyline',
  ADD_RECTANGLE: 'add_rectangle',
  ADD_RING: 'add_ring',
  AVOID_OVERLAP: 'avoid_overlap',
  SPLIT_LINE: 'split_line',
  SELECT: 'select_feature',
  ADD_RESHAPE: 'add_reshape',
  ENABLE_SNAPPING: 'enable_snapping',
  UNDO: 'undo',
  REDO: 'redo',
  MOVE: 'move_feature',
  MEASURE: 'measure',
  MODIFY_SEGMENT: 'modify_segment',
  COPY_AND_MOVE: 'copy_and_move_feature',
  SPLIT_POLYGON: 'split_feature',
  ADD_ABSTRACT: 'abstract',
  ADD_SEGMENT: 'add_segment',
  TEXT_DETECTION: 'text_detection',
  LINE_SNIPPING: 'line_snipping',
  LINE_SNIPPING_TWO_POINT: 'line_snipping_two_point',
  ADD_CIRCLE: 'add_circle',
  ADD_RECTANGULAR_HOLE: 'add_rectangular_hole',
  ADD_RECTANGULAR_FILL: 'add_rectangular_fill',
  ADD_CIRCULAR_HOLE: 'add_circular_hole',
  ADD_CIRCULAR_FILL: 'add_circular_fill',
  DELETE_RING: 'delete_ring',
  FILL_RING: 'fill_ring',
  POLYGON_TO_LINE: 'poly_to_line',
  ADD_CURVED_POLYGON: 'add_curved_polygon',
  ADD_CURVED_LINE: 'add_curved_line',
  ADD_HIGHLIGHT: 'add_highlight',
  EXTERNAL_HIGHLIGHT: 'external_highlight',
  POLYGON_FILLING: 'polygon_filling',
  POLYLINE_FILLING: 'polyline_filling',
  POLYGON_SNAPPING: 'polygon_snapping',
  POLYLINE_SNAPPING: 'polyline_snapping',
  MODIFY_VERTEX: 'modify_vertex',
  LINE_SCALE: 'line_scale',
  RECLASSIFY: 'reclassify',
  COPY_COORDS: 'copy_coords',
  MULTI_SELECT: 'multi_select',
  ADD_COMMENT: 'add_comment',
  PANEL_CLICK: 'panel_click',
  RECLASSIFY_MERGE: 'reclassify_merge',
  SHOW_VERTICES: 'show_vertices',
  ARROW: 'add_arrow',
  TEXT_BOX: 'text_box',
  TAG: 'add_tag',
  GROUP_TAG: 'add_group_tag',
  COMMENT_BPT: 'comment_bpt',
  COPY_SEGMENT: 'copy_segment',
  HAND_DRAWING_TOOL: 'hand_drawing',
  DRAG_ROTATE: 'drag_rotate',
  ROTATE_CLOCKWISE: 'rotate_clockwise',
  ROTATE_COUNTER_CLOCKWISE: 'rotate_counter_clockwise',
  FLIP_HORIZONTAL: 'flip_horizontal',
  FLIP_VERTICAL: 'flip_vertical',
  PAGE_CLOCKWISE_ROTATE: 'page_clockwise_rotate',
  PAGE_ANTICLOCKWISE_ROTATE: 'page_anticlockwise_rotate',
  ADD_LINE_RECTANGLE: 'add_line_rectangle',
  ADD_LINE_CIRCLE: 'add_line_circle'
};

export const ROTATE_SUBTOOLS = [
  TOOL_TYPE.ROTATE_CLOCKWISE,
  TOOL_TYPE.ROTATE_COUNTER_CLOCKWISE,
  TOOL_TYPE.FLIP_HORIZONTAL,
  TOOL_TYPE.FLIP_VERTICAL
];

/*Acion ids */
export const ACTION = {
  MERGE: 'merge',
  DELETE: 'delete',
  UNDO: 'undo',
  REDO: 'redo',
  RECLASSIFY: 'reclassify',
  PAGE_CLOCKWISE_ROTATE: 'page_clockwise_rotate',
  PAGE_ANTICLOCKWISE_ROTATE: 'page_anticlockwise_rotate',
  ROTATE_CLOCKWISE: 'rotate_clockwise',
  ROTATE_ANTI_CLOCKWISE: 'rotate_anti_clockwise',
  FLIP_VERTICAL: 'flip_vertical',
  FLIP_HORIZONTAL: 'flip_horizontal'
};

export const TOOL_NAMES = {
  [TOOL_TYPE.ADD_POLYGON]: 'Add Polygon',
  [TOOL_TYPE.PAN]: 'Pan',
  [TOOL_TYPE.ADD_POINT]: 'Add Point',
  [TOOL_TYPE.ADD_POLYLINE]: 'Add Polyline',
  [TOOL_TYPE.ADD_RECTANGLE]: 'Add Rectangle',
  [TOOL_TYPE.ADD_RING]: 'Add Ring',
  [TOOL_TYPE.AVOID_OVERLAP]: 'Avoid Overlap',
  [TOOL_TYPE.SPLIT_LINE]: 'Split Line',
  [TOOL_TYPE.SPLIT_POLYGON]: 'Split polygon',
  [TOOL_TYPE.SELECT]: 'Select Feature',
  [TOOL_TYPE.ADD_RESHAPE]: 'Add Reshape',
  [TOOL_TYPE.ENABLE_SNAPPING]: 'Enable Snapping',
  [TOOL_TYPE.UNDO]: 'Undo',
  [TOOL_TYPE.REDO]: 'Redo',
  [TOOL_TYPE.MOVE]: 'Move Feature',
  [TOOL_TYPE.MEASURE]: 'Measure',
  [TOOL_TYPE.MODIFY_SEGMENT]: 'Modify Segment',
  [TOOL_TYPE.COPY_AND_MOVE]: 'Copy and Move',
  [ACTION.DELETE]: 'Delete',
  [ACTION.MERGE]: 'Merge',
  [TOOL_TYPE.ADD_ABSTRACT]: 'Abstract',
  [TOOL_TYPE.ADD_SEGMENT]: 'Add Segment',
  [TOOL_TYPE.TEXT_DETECTION]: 'Text Detection',
  [TOOL_TYPE.LINE_SNIPPING]: 'Line Snipping',
  [TOOL_TYPE.LINE_SNIPPING_TWO_POINT]: 'Line Snipping (2 point)',
  [TOOL_TYPE.ADD_CIRCLE]: 'Add circle',
  [TOOL_TYPE.ADD_RECTANGULAR_HOLE]: 'Add Rectangular hole',
  [TOOL_TYPE.ADD_RECTANGULAR_FILL]: 'Add Rectangular fill',
  [TOOL_TYPE.ADD_CIRCULAR_HOLE]: 'Add Circular hole',
  [TOOL_TYPE.ADD_CIRCULAR_FILL]: 'Add Circular fill',
  [TOOL_TYPE.DELETE_RING]: 'Delete Ring',
  [TOOL_TYPE.FILL_RING]: 'Fill Ring',
  [TOOL_TYPE.POLYGON_TO_LINE]: 'Polygon to line',
  [TOOL_TYPE.ADD_CURVED_POLYGON]: 'Add Curve polygon',
  [TOOL_TYPE.ADD_CURVED_LINE]: 'Add Curve line',
  [TOOL_TYPE.ADD_HIGHLIGHT]: 'Add Highlight',
  [TOOL_TYPE.EXTERNAL_HIGHLIGHT]: 'Add Highlight',
  [TOOL_TYPE.POLYGON_FILLING]: 'Ai polygon filling',
  [TOOL_TYPE.POLYLINE_FILLING]: 'Ai polyline filling',
  [TOOL_TYPE.POLYGON_SNAPPING]: 'Ai polygon snapping',
  [TOOL_TYPE.POLYLINE_SNAPPING]: 'Ai polyline snapping',
  [TOOL_TYPE.MODIFY_VERTEX]: 'Modify vertex',
  [TOOL_TYPE.LINE_SCALE]: 'Line Scale',
  [TOOL_TYPE.MULTI_SELECT]: 'Multi select',
  [TOOL_TYPE.ADD_COMMENT]: 'Add Comment',
  [TOOL_TYPE.COPY_COORDS]: 'Copy Coodinates',
  [TOOL_TYPE.RECLASSIFY]: 'Reclassify',
  [TOOL_TYPE.SHOW_VERTICES]: 'Show vertices',
  [TOOL_TYPE.ARROW]: 'Arrow',
  [TOOL_TYPE.TEXT_BOX]: 'Text box',
  [TOOL_TYPE.TAG]: 'Tag',
  [TOOL_TYPE.GROUP_TAG]: 'Group',
  [TOOL_TYPE.COMMENT_BPT]: 'Comment',
  [TOOL_TYPE.COPY_SEGMENT]: 'Copy Segment',
  [TOOL_TYPE.HAND_DRAWING_TOOL]: 'Free hand',
  [TOOL_TYPE.FLIP_HORIZONTAL]: 'Flip horizontal',
  [TOOL_TYPE.FLIP_VERTICAL]: 'Flip vertical',
  [TOOL_TYPE.ROTATE_CLOCKWISE]: 'Rotate right',
  [TOOL_TYPE.ROTATE_COUNTER_CLOCKWISE]: 'Rotate left',
  [TOOL_TYPE.DRAG_ROTATE]: 'Rotate',
  [TOOL_TYPE.PAGE_CLOCKWISE_ROTATE]: 'Page clockwise rotate',
  [TOOL_TYPE.PAGE_ANTICLOCKWISE_ROTATE]: 'Page anticlockwise rotate',
  [TOOL_TYPE.ADD_LINE_RECTANGLE]: 'Add Line Rectangle',
  [TOOL_TYPE.ADD_LINE_CIRCLE]: 'Add Line Circle'
} as const;

const TOOL_MAP: { [key: string]: Tool } = {
  [TOOL_TYPE.COMMENT_BPT]: new Tool(
    TOOL_NAMES.comment_bpt,
    TOOL_TYPE.COMMENT_BPT,
    [POLYGON, POINT, ABSTRACT, LINE, TYPICAL],
    AddCommentBPT
  ),
  [TOOL_TYPE.ADD_POLYGON]: new Tool(TOOL_NAMES.add_polygon, TOOL_TYPE.ADD_POLYGON, [POLYGON], DrawPolygon),
  [TOOL_TYPE.ADD_POINT]: new Tool(TOOL_NAMES.add_point, TOOL_TYPE.ADD_POINT, [POINT], DrawPoint),
  [TOOL_TYPE.ADD_RECTANGLE]: new Tool(TOOL_NAMES.add_rectangle, TOOL_TYPE.ADD_RECTANGLE, [POLYGON], AddRectangle),

  [TOOL_TYPE.ADD_RING]: new Tool(TOOL_NAMES.add_ring, TOOL_TYPE.ADD_RING, [POLYGON], AddRing),
  [TOOL_TYPE.SELECT]: new Tool(TOOL_NAMES.select_feature, TOOL_TYPE.SELECT, [POLYGON, POINT, LINE], SelectTool),
  [TOOL_TYPE.MULTI_SELECT]: new Tool(
    TOOL_NAMES.multi_select,
    TOOL_TYPE.MULTI_SELECT,
    [POLYGON, POINT, LINE, ABSTRACT, TYPICAL],
    MultiSelectTool
  ),
  [TOOL_TYPE.MOVE]: new Tool(TOOL_NAMES.move_feature, TOOL_TYPE.MOVE, [POLYGON, POINT, LINE, TYPICAL], MoveTool),
  [TOOL_TYPE.COPY_AND_MOVE]: new Tool(
    TOOL_NAMES.copy_and_move_feature,
    TOOL_TYPE.COPY_AND_MOVE,
    [POLYGON, POINT, LINE, TYPICAL],
    CopyAndMoveTool
  ),
  [TOOL_TYPE.MEASURE]: new Tool(TOOL_NAMES.measure, TOOL_TYPE.MEASURE, [POLYGON, POINT, LINE, TYPICAL], DimensionTool),
  [TOOL_TYPE.ADD_RESHAPE]: new Tool(TOOL_NAMES.add_reshape, TOOL_TYPE.ADD_RESHAPE, [POLYGON], ReshapePolygon),
  [TOOL_TYPE.ADD_POLYLINE]: new Tool(TOOL_NAMES.add_polyline, TOOL_TYPE.ADD_POLYLINE, [LINE], AddPolyLine),
  [TOOL_TYPE.SPLIT_LINE]: new Tool(TOOL_NAMES.split_line, TOOL_TYPE.SPLIT_LINE, [LINE], SplitLine),
  [TOOL_TYPE.SPLIT_POLYGON]: new Tool(TOOL_NAMES.split_feature, TOOL_TYPE.SPLIT_POLYGON, [POLYGON], SplitPolygon),
  [TOOL_TYPE.ADD_ABSTRACT]: new Tool(
    TOOL_NAMES.abstract,
    TOOL_TYPE.ADD_ABSTRACT,
    [GEOMETRY_TYPE.ABSTRACT],
    AddAbstract
  ),
  [TOOL_TYPE.ADD_SEGMENT]: new Tool(TOOL_NAMES.add_segment, TOOL_TYPE.ADD_SEGMENT, [GEOMETRY_TYPE.LINE], AddSegment),
  [TOOL_TYPE.TEXT_DETECTION]: new Tool(
    TOOL_NAMES.text_detection,
    TOOL_TYPE.TEXT_DETECTION,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    TextDetection
  ),
  [TOOL_TYPE.LINE_SNIPPING]: new Tool(
    TOOL_NAMES.line_snipping,
    TOOL_TYPE.LINE_SNIPPING,
    [GEOMETRY_TYPE.LINE],
    LineSnippingTool
  ),
  [TOOL_TYPE.LINE_SNIPPING_TWO_POINT]: new Tool(
    TOOL_NAMES.line_snipping_two_point,
    TOOL_TYPE.LINE_SNIPPING_TWO_POINT,
    [GEOMETRY_TYPE.LINE],
    LineSnippingTool
  ),
  [TOOL_TYPE.ADD_CIRCLE]: new Tool(TOOL_NAMES.add_circle, TOOL_TYPE.ADD_CIRCLE, [GEOMETRY_TYPE.POLYGON], AddCircle),
  [TOOL_TYPE.ADD_RECTANGULAR_HOLE]: new Tool(
    TOOL_NAMES.add_rectangular_hole,
    TOOL_TYPE.ADD_RECTANGULAR_HOLE,
    [GEOMETRY_TYPE.POLYGON],
    AddRectangularHole
  ),
  [TOOL_TYPE.ADD_RECTANGULAR_FILL]: new Tool(
    TOOL_NAMES.add_rectangular_fill,
    TOOL_TYPE.ADD_RECTANGULAR_FILL,
    [GEOMETRY_TYPE.POLYGON],
    AddRectangularFill
  ),
  [TOOL_TYPE.ADD_CIRCULAR_FILL]: new Tool(
    TOOL_NAMES.add_circular_fill,
    TOOL_TYPE.ADD_CIRCULAR_FILL,
    [GEOMETRY_TYPE.POLYGON],
    AddCircularFill
  ),
  [TOOL_TYPE.ADD_CIRCULAR_HOLE]: new Tool(
    TOOL_NAMES.add_circular_hole,
    TOOL_TYPE.ADD_CIRCULAR_HOLE,
    [GEOMETRY_TYPE.POLYGON],
    AddCicularHole
  ),
  [TOOL_TYPE.DELETE_RING]: new Tool(TOOL_NAMES.delete_ring, TOOL_TYPE.DELETE_RING, [GEOMETRY_TYPE.POLYGON], DeleteRing),
  [TOOL_TYPE.FILL_RING]: new Tool(TOOL_NAMES.fill_ring, TOOL_TYPE.FILL_RING, [GEOMETRY_TYPE.POLYGON], AddFillRing),
  [TOOL_TYPE.POLYGON_TO_LINE]: new Tool(
    TOOL_NAMES.poly_to_line,
    TOOL_TYPE.POLYGON_TO_LINE,
    [GEOMETRY_TYPE.LINE],
    PolygonToLine
  ),
  [TOOL_TYPE.COPY_SEGMENT]: new Tool(
    TOOL_NAMES.poly_to_line,
    TOOL_TYPE.COPY_SEGMENT,
    [GEOMETRY_TYPE.POLYGON],
    CopySegment
  ),
  [TOOL_TYPE.ADD_CURVED_POLYGON]: new Tool(
    TOOL_NAMES.add_curved_polygon,
    TOOL_TYPE.ADD_CURVED_POLYGON,
    [GEOMETRY_TYPE.POLYGON],
    AddCurvedPolygon
  ),
  [TOOL_TYPE.ADD_CURVED_LINE]: new Tool(
    TOOL_NAMES.add_curved_line,
    TOOL_TYPE.ADD_CURVED_LINE,
    [GEOMETRY_TYPE.LINE],
    AddCurvedLine
  ),
  [TOOL_TYPE.ADD_HIGHLIGHT]: new Tool(
    TOOL_NAMES.add_highlight,
    TOOL_TYPE.ADD_HIGHLIGHT,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    HighlightTool
  ),
  [TOOL_TYPE.EXTERNAL_HIGHLIGHT]: new Tool(
    TOOL_NAMES.add_highlight,
    TOOL_TYPE.EXTERNAL_HIGHLIGHT,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    HighlightTool
  ),
  [TOOL_TYPE.HAND_DRAWING_TOOL]: new Tool(
    TOOL_NAMES.hand_drawing,
    TOOL_TYPE.HAND_DRAWING_TOOL,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.POINT],
    HandDrawingTool
  ),
  [TOOL_TYPE.MODIFY_SEGMENT]: new Tool(
    TOOL_NAMES.modify_segment,
    TOOL_TYPE.MODIFY_SEGMENT,
    [GEOMETRY_TYPE.POLYGON],
    ModifySegment
  ),
  [TOOL_TYPE.POLYGON_SNAPPING]: new Tool(
    TOOL_NAMES.polygon_snapping,
    TOOL_TYPE.POLYGON_SNAPPING,
    [GEOMETRY_TYPE.POLYGON],
    PolygonSnapping
  ),
  [TOOL_TYPE.POLYLINE_SNAPPING]: new Tool(
    TOOL_NAMES.polyline_snapping,
    TOOL_TYPE.POLYLINE_SNAPPING,
    [GEOMETRY_TYPE.LINE],
    PolylineSnapping
  ),
  [TOOL_TYPE.POLYGON_FILLING]: new Tool(
    TOOL_NAMES.polygon_filling,
    TOOL_TYPE.POLYGON_FILLING,
    [GEOMETRY_TYPE.POLYGON],
    PolygonFilling
  ),
  [TOOL_TYPE.POLYLINE_FILLING]: new Tool(
    TOOL_NAMES.polyline_filling,
    TOOL_TYPE.POLYGON_FILLING,
    [GEOMETRY_TYPE.LINE],
    PolylineFilling
  ),
  [TOOL_TYPE.MODIFY_VERTEX]: new Tool(
    TOOL_NAMES.modify_vertex,
    TOOL_TYPE.MODIFY_VERTEX,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    ModifyVertex
  ),
  [TOOL_TYPE.LINE_SCALE]: new Tool(
    TOOL_NAMES.line_scale,
    TOOL_TYPE.LINE_SCALE,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    LineScale
  ),
  [TOOL_TYPE.ENABLE_SNAPPING]: new Tool(
    TOOL_NAMES.enable_snapping,
    TOOL_TYPE.ENABLE_SNAPPING,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    AddSnapping
  ),
  [TOOL_TYPE.ADD_COMMENT]: new Tool(
    TOOL_NAMES.add_comment,
    TOOL_TYPE.ADD_COMMENT,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    AddComment
  ),
  [TOOL_TYPE.COPY_COORDS]: new Tool(
    TOOL_NAMES.copy_coords,
    TOOL_TYPE.COPY_COORDS,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    CopyCoordinatesTool
  ),
  [TOOL_TYPE.SHOW_VERTICES]: new Tool(
    TOOL_NAMES.show_vertices,
    TOOL_TYPE.SHOW_VERTICES,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    ShowVertex
  ),
  [TOOL_TYPE.ARROW]: new Tool(
    TOOL_NAMES.add_arrow,
    TOOL_TYPE.ARROW,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    ArrowTool
  ),
  [TOOL_TYPE.TEXT_BOX]: new Tool(
    TOOL_NAMES.text_box,
    TOOL_TYPE.TEXT_BOX,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    TextBox
  ),
  [TOOL_TYPE.TAG]: new Tool(
    TOOL_NAMES[TOOL_TYPE.TAG],
    TOOL_TYPE.TAG,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    TaggingTool
  ),
  [TOOL_TYPE.GROUP_TAG]: new Tool(
    TOOL_NAMES[TOOL_TYPE.GROUP_TAG],
    TOOL_TYPE.GROUP_TAG,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON, GEOMETRY_TYPE.ABSTRACT, GEOMETRY_TYPE.POINT, GEOMETRY_TYPE.TYPICAL],
    TaggingTool
  ),
  [TOOL_TYPE.FLIP_HORIZONTAL]: new Tool(
    TOOL_NAMES[TOOL_TYPE.FLIP_HORIZONTAL],
    TOOL_TYPE.FLIP_HORIZONTAL,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    FlipTool
  ),
  [TOOL_TYPE.FLIP_VERTICAL]: new Tool(
    TOOL_NAMES[TOOL_TYPE.FLIP_VERTICAL],
    TOOL_TYPE.FLIP_VERTICAL,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    FlipTool
  ),
  [TOOL_TYPE.ROTATE_COUNTER_CLOCKWISE]: new Tool(
    TOOL_NAMES[TOOL_TYPE.ROTATE_COUNTER_CLOCKWISE],
    TOOL_TYPE.ROTATE_COUNTER_CLOCKWISE,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    RotateTool
  ),
  [TOOL_TYPE.ROTATE_CLOCKWISE]: new Tool(
    TOOL_NAMES[TOOL_TYPE.ROTATE_CLOCKWISE],
    TOOL_TYPE.ROTATE_CLOCKWISE,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    RotateTool
  ),
  [TOOL_TYPE.DRAG_ROTATE]: new Tool(
    TOOL_NAMES[TOOL_TYPE.DRAG_ROTATE],
    TOOL_TYPE.DRAG_ROTATE,
    [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    DragRotate
  ),
  [TOOL_TYPE.ADD_LINE_RECTANGLE]: new Tool(
    TOOL_NAMES[TOOL_TYPE.ADD_LINE_RECTANGLE],
    TOOL_TYPE.ADD_LINE_RECTANGLE,
    [GEOMETRY_TYPE.LINE],
    AddLineRectangle
  ),
  [TOOL_TYPE.ADD_LINE_CIRCLE]: new Tool(
    TOOL_NAMES[TOOL_TYPE.ADD_LINE_CIRCLE],
    TOOL_TYPE.ADD_LINE_CIRCLE,
    [GEOMETRY_TYPE.CIRCLE],
    AddLineCircle
  )
};
// Create a Abstract class for base tool

export const ACTION_MAP: { [key: string]: Action } = {
  [ACTION.UNDO]: new Action(TOOL_NAMES.undo, ACTION.UNDO, Undo),
  [ACTION.REDO]: new Action(TOOL_NAMES.redo, ACTION.REDO, Redo),
  [ACTION.DELETE]: new Action(TOOL_NAMES.delete, ACTION.DELETE, Delete),
  [ACTION.MERGE]: new Action(TOOL_NAMES.merge, ACTION.MERGE, Merge),
  [ACTION.ROTATE_CLOCKWISE]: new Action(TOOL_NAMES.ROTATE_CLOCKWISE, ACTION.ROTATE_CLOCKWISE, RotateClockwise),
  [ACTION.ROTATE_ANTI_CLOCKWISE]: new Action(
    TOOL_NAMES.ROTATE_COUNTER_CLOCKWISE,
    ACTION.ROTATE_ANTI_CLOCKWISE,
    RotateAntiClockwise
  ),
  [ACTION.FLIP_VERTICAL]: new Action(TOOL_NAMES.FLIP_VERTICAL, ACTION.FLIP_VERTICAL, FlipVertically),
  [ACTION.FLIP_HORIZONTAL]: new Action(TOOL_NAMES.FLIP_HORIZONTAL, ACTION.FLIP_HORIZONTAL, FlipHorizontally),
  [ACTION.RECLASSIFY]: new Action(TOOL_NAMES.reclassify, ACTION.RECLASSIFY, Reclassify),
  [ACTION.PAGE_CLOCKWISE_ROTATE]: new Action(
    TOOL_NAMES.page_clockwise_rotate,
    ACTION.PAGE_CLOCKWISE_ROTATE,
    ClockwiseRotation
  ),
  [ACTION.PAGE_ANTICLOCKWISE_ROTATE]: new Action(
    TOOL_NAMES.page_anticlockwise_rotate,
    ACTION.PAGE_ANTICLOCKWISE_ROTATE,
    AntiClockwiseRotation
  )
};

export const ROTATE_OPTIONS: ToolInterface[] = [
  {
    title: 'Rotate right ',
    icon: () => RotateClockwiseIcon({}),
    tool_id: ACTION.ROTATE_CLOCKWISE,
    is_action: true,
    shortcut: 'alt+right'
  },
  {
    title: 'Rotate left',
    icon: () => RotateAntiClockwiseIcon({}),
    tool_id: ACTION.ROTATE_ANTI_CLOCKWISE,
    is_action: true,
    shortcut: 'alt+left'
  },
  {
    title: 'Flip horizontal ',
    icon: () => FlipHorizontalIcon({}),
    tool_id: ACTION.FLIP_HORIZONTAL,
    is_action: true,
    shortcut: 'alt+h'
  },
  {
    title: 'Flip vertical ',
    icon: () => FlipVerticalIcon({}),
    tool_id: ACTION.FLIP_VERTICAL,
    is_action: true,
    shortcut: 'alt+v'
  }
];

export default TOOL_MAP;
