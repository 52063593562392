import Map from 'ol/Map';
import Tile from 'ol/layer/Tile';
import TileImage from 'ol/source/TileImage';
import Static from 'ol/source/ImageStatic';
import ImageLayer from 'ol/layer/Image';
import View from 'ol/View';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import { showToast } from '@attentive-platform/stem-ui';

import {
  BASE_LAYER,
  GEOMETRY_TYPES,
  GOOGLE_IMAGERY_SATELLITE,
  IMAGE_BOUNDS_BASE_LAYER_TYPE,
  IMAGE_TILE_LAYER_TYPE,
  ZOOM,
  GOOGLE_LAYER,
  IMAGE_STATIC_ORTHO,
  DATA_PROJECTION,
  FEATURE_PROJECTION,
  MULTI_GEOMETRIES,
  BPT_PROJECTION,
  MAP_TYPE,
  CurrentJob,
  DEFAULT_TOOL,
  MAP_CONTAINER_ID,
  Feature,
  GEOMETRY_TYPE,
  GEO_JSON_OBJECT_PROJECTION,
  CurrentSheet,
  DIMENSION_TOOL_LAYER,
  HIGHLIGHT_TOOL_LAYER,
  DEFAULT_ZOOM_VALUE,
  MIN_ZOOM_VALUE,
  GEOMETRY_Z_INDEX,
  AUTO_SCALE_TOOL_LAYER,
  ARROW_TOOL,
  HAND_TOOL_LAYER
} from 'woodpecker';
import { DragPan, KeyboardPan, KeyboardZoom, Snap, MouseWheelZoom } from 'ol/interaction';
import { Collection } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { Projection, fromLonLat, transformExtent } from 'ol/proj';
import { bbox, buffer } from '@turf/turf';
import { GEO_JSON } from 'macaw';
import { Fill, Stroke, Style, Text, Circle as CircleStyle } from 'ol/style';
import { Cluster, XYZ } from 'ol/source';
import { Point } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import { getCenter } from 'ol/extent';
import _ from 'lodash';
import VectorLayer from 'ol/layer/Vector';
import BaseLayer from 'ol/layer/Base';
import { getImageWidthAndHeight } from '../../../helpers/image';
import { PARCEL } from '../../../hooks/tools/helpers/constants';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import {
  getAerialGeometryType,
  getLayerType,
  getTileType,
  highlightFeatureIfInvalid,
  isValidGeojson,
  transformMutiPolyToFeatures
} from '../../../helpers/helpers';
import { canEnablePanning } from '../../../helpers/interactionUtils';

import {
  ModifiedStyleGenFn,
  styleFunctionArrow,
  styleFunctionDimenSionTool,
  styleFunctionHandTool,
  styleFunctionHightTool
} from '../../../hooks/addition';
import { useTags } from '../../../store/tagsStore';
import { filterOutDeletedTags } from '../../../helpers/tagging';

const TILE_SERVER_URL = `${process.env.NEXT_PUBLIC_FXS_BASE_URL}`;
const MIN_ZOOM = 0;
const MAX_ZOOM = 5;
class MapBase {
  private static instance: MapBase;

  public map: Map | null;

  private base_layer: Tile<TileImage> | null;

  private google_layer: Tile<TileImage> | null;

  private oblique_images: any = {};

  private ortho_image: any = {};

  public map_type: number = 1;

  private source: any = null;

  private constructor() {
    this.map = null;
    this.base_layer = null;
    this.google_layer = null;
  }

  public static getInstance(): MapBase {
    if (!MapBase.instance) {
      MapBase.instance = new MapBase();
    }
    return MapBase.instance;
  }

  getInteractions() {
    return [
      new DragPan({
        condition: e => {
          const { AppStore: newAppStore } = globalStore;
          return canEnablePanning(e.originalEvent, newAppStore?.tool?.tool_id);
        }
      }),
      new KeyboardPan({ duration: 10 }),
      new KeyboardZoom({ duration: 10 }),
      new MouseWheelZoom({ duration: 10, maxDelta: 32, timeout: 10 })
    ];
  }

  /**
   * Destroys the map object by removing its target and disposing it.
   * After calling this method, the map object should no longer be used.
   */
  destroyMap() {
    if (!this.map) return;

    this.map.setTarget(undefined);
    this.map.dispose();
    this.map = null;
  }

  /**
   * Initializes the map object based on the specified map type.
   * If the map object is already initialized, returns the existing map object.
   * @param {number} map_type - The type of map to initialize (e.g., MAP_TYPE.AERIAL).
   * @returns {Map} The initialized map object.
   */
  init(map_type: number): Map {
    if (this.map) return this.map;

    this.map_type = map_type;
    const interactions = new Collection(this.getInteractions());
    this.map = new Map({
      controls: defaultControls({ zoom: false }),
      interactions,
      target: MAP_CONTAINER_ID,
      layers: [],
      view: new View({
        center: fromLonLat([-104, 39]),
        zoom: ZOOM,
        minZoom: MIN_ZOOM_VALUE,
        maxZoom: DEFAULT_ZOOM_VALUE,
        extent: transformExtent([-180, -90, 180, 90], DATA_PROJECTION, FEATURE_PROJECTION)
      }),
      keyboardEventTarget: document
    });
    this.oblique_images = {};
    this.ortho_image = {};

    if (map_type === MAP_TYPE.AERIAL) {
      this.addBaseLayer();
      this.addGoogleBaseLayer();
    }

    return this.map;
  }

  getLayerById(id: string): any {
    return this.map
      ?.getLayers()
      .getArray()
      .find(lyr => id == lyr.get('id'));
  }

  addBaseLayer() {
    const source = new OSM();
    this.base_layer = new Tile({
      source,
      // @ts-ignore id is private
      id: BASE_LAYER,
      zIndex: 0
    });
    this.map!.addLayer(this.base_layer);
  }

  addGoogleBaseLayer() {
    const source = new TileImage({
      url: GOOGLE_IMAGERY_SATELLITE
    });
    this.google_layer = new Tile({
      source,
      // @ts-ignore id is private
      id: GOOGLE_LAYER,
      zIndex: 0
    });
    this.google_layer.setVisible(false);
    this.map!.addLayer(this.google_layer);
  }

  /**
   * Zooms the map to fit the specified extent.
   * @param {Array<number>} extent - The extent to fit the map to, in the format [minx, miny, maxx, maxy].
   */
  zoomToExtent(extent: Array<number>) {
    this.map!.getView().fit(extent, {
      duration: 300,
      maxZoom: 21,
      size: this.map!.getSize()
    });
  }

  /**
   * Loads an image layer onto the map.
   * @param {boolean} [zoom=false] - Whether to zoom to the extent of the loaded image.
   * @param {boolean} [tileserver_as_baselayer=false] - Whether the tileserver URL should be treated as a base layer.
   */
  loadImageLayer(zoom: boolean = false, tileserver_as_baselayer: boolean = false) {
    if (!this.ortho_image) {
      console.log('Attempt to load non existing ortho layer');
      return;
    }

    const url = this.ortho_image?.url ?? '';
    const { tileserver_url } = this.ortho_image;
    const _bounds = this.ortho_image?.bounds;
    let _bx;
    if (tileserver_as_baselayer) {
      _bx = transformExtent([_bounds[0], _bounds[1], _bounds[2], _bounds[3]], DATA_PROJECTION, FEATURE_PROJECTION);
    } else {
      _bx = transformExtent(
        [_bounds?.left, _bounds?.bottom, _bounds?.right, _bounds?.top],
        DATA_PROJECTION,
        FEATURE_PROJECTION
      );
    }
    const seTilesData = globalStore.AppStore.setTilesData;
    let totalTilesLoaded = 0;

    if (tileserver_url) {
      const source = new TileImage({
        url: tileserver_url
      });
      const tileType = getTileType(tileserver_url);

      // Attach event listener to increment the counter
      source.on('tileloadstart', () => {
        totalTilesLoaded += 1;
        seTilesData({
          totalTilesLoaded,
          tileType
        });
      });

      const img_layer = new Tile({
        // @ts-ignore id is private
        id: IMAGE_STATIC_ORTHO,
        source,
        zIndex: 1,
        maxZoom: DEFAULT_ZOOM_VALUE
      });

      this.map!.addLayer(img_layer);
      img_layer.setExtent(_bx);
    } else {
      const img_src = new Static({
        url,
        imageExtent: _bx
      });
      const img_layer = new ImageLayer({
        // @ts-ignore id is private
        id: IMAGE_STATIC_ORTHO,
        source: img_src,
        zIndex: 1
      });

      this.map!.addLayer(img_layer);
    }

    if (zoom) {
      this.zoomToExtent(_bx);
    }
    // this.getAppstore().setOrthoImageVisible(true);
  }

  /**
   * Adds an image layer to the map using the specified URL, bounds, and tileserver URL.
   * @param {string} url - The URL of the image layer.
   * @param {Object} [_bounds={}] - The bounds of the image layer.
   * @param {string} tileserver_url - The URL of the tileserver for the image layer.
   * @param {boolean} [tileserver_as_baselayer=false] - Whether the tileserver URL should be treated as a base layer.
   */
  addImageLayer(url: string, _bounds: object = {}, tileserver_url: string, tileserver_as_baselayer: boolean = false) {
    this.ortho_image.url = url;
    this.ortho_image.bounds = _bounds;
    this.ortho_image.tileserver_url = tileserver_url;
    this.loadImageLayer(true, tileserver_as_baselayer);
  }

  /**
   * Loads an aerial layer based on the data provided in the CurrentJob object.
   * If the base layer type is IMAGE_BOUNDS_BASE_LAYER_TYPE, adds an image layer with the specified base layer, image bounds, and tileserver URL.
   * If the base layer type is IMAGE_TILE_LAYER_TYPE, adds a base layer as a tileserver URL.
   * @param {CurrentJob} data - The CurrentJob object containing the data for the aerial layer.
   */
  loadAerialLayer = (data: CurrentJob) => {
    if (data?.base_layer_type === IMAGE_BOUNDS_BASE_LAYER_TYPE) {
      this.addImageLayer(data.base_layer, data.image_bounds, data.tileserver_url);
    } else if (data?.base_layer_type === IMAGE_TILE_LAYER_TYPE) {
      let parcelGeoJson = data?.layers[0]?.original_json;
      if (!parcelGeoJson || !isValidGeojson(parcelGeoJson)) {
        parcelGeoJson = data.boundary_layer_json;
      }
      const bufferedBbox = buffer(parcelGeoJson, 0.1);
      const bBox = bbox(bufferedBbox);
      this.addImageLayer('', bBox, data.base_layer, true);
    } else {
      this.addImageLayer(data?.base_layer, data?.image_bounds, '');
    }
  };

  saveData = _.debounce(() => {
    const onTriggerSave = globalStore.AppStore.onsaveData;
    if (onTriggerSave) onTriggerSave();
  }, 500);

  changeOpacity(id: string, opacity: number) {
    const { scale, dpi } = globalStore.AppStore.worksheetParams;
    const { layers } = globalStore.AppStore;

    let newStyle;
    /*Different styles for overlay layers */
    if (id === DIMENSION_TOOL_LAYER || id === HIGHLIGHT_TOOL_LAYER) {
      if (id === HIGHLIGHT_TOOL_LAYER) {
        newStyle = (feature: any) => styleFunctionHightTool(feature);
      } else {
        newStyle = (feature: any) => styleFunctionDimenSionTool(feature, scale, dpi);
      }
    } else {
      const geometry_type = getLayerType(id)?.geometry_type;
      newStyle = ModifiedStyleGenFn(id, layers, opacity, geometry_type);
    }

    const _layer = this.getLayerById(id);

    if (_layer) {
      _layer.setStyle(newStyle);
    }
  }

  // const source = useRef<any>();
  addVectorLayerV2 = (json: any) => {
    if (!json || !json?.features) {
      json = {
        type: 'FeatureCollection',
        features: []
      };
    } else {
      json = {
        type: json.type,
        features: json.features
      };
    }
    const projection = this.map?.getView().getProjection();
    let localSource = this.source;
    if (!localSource) {
      const _source = new VectorSource({
        wrapX: false
      });
      this.source = _source;
      localSource = _source;
    }
    if (!projection) {
      return;
    }
    // @ts-ignore
    localSource.addFeatures(GEO_JSON.readFeatures(json, projection));
    const clusterSource = new Cluster({
      distance: 10,
      source: localSource,
      wrapX: false,
      // @ts-ignore
      geometryFunction(feature) {
        const geometry = feature.getGeometry();
        if (!geometry) return new Point([0, 0]);
        const type = geometry.getType();
        // if (type == "Point") {
        return geometry;
        // }
        // return null;
      }
    });
    const styleCache = {};
    const clusters = new VectorLayer({
      source: clusterSource,
      style(feature) {
        const size = feature.get('features').length;
        // @ts-ignore
        let style = styleCache[size];
        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: 10,
              stroke: new Stroke({
                color: '#fff'
              }),
              fill: new Fill({
                color: '#3399CC'
              })
            }),
            text: new Text({
              text: size.toString(),
              fill: new Fill({
                color: '#fff'
              })
            })
          });
          // @ts-ignore
          styleCache[size] = style;
        }
        return style;
      }
    });
    setTimeout(() => {
      this.map?.addLayer(clusters);
    }, 1000);
  };

  /**
   * Adds a vector layer to the map based on the provided GeoJSON feature and layer ID.
   * @param {Feature} json - The GeoJSON feature to add as a vector layer.
   * @param {string} id - The ID of the vector layer.
   * @param {number} opacity - The opacity for features styling.
   * @returns {VectorSource} The vector source of the added layer.
   */
  addVectorLayer(
    json: { features: Feature[]; type: 'FeatureCollection' },
    id: string,
    opacity: number,
    default_tags: any = {},
    isAnnotationLayer: boolean = false,
    layerVisibility: boolean = true
  ): VectorSource {
    if (!json || !isValidGeojson(json))
      json = {
        type: 'FeatureCollection',
        features: []
      };

    const isBlueprintMap = this.map_type === MAP_TYPE.BLUEPRINT;

    // removing tags from geometries which are deleted from tags library
    json?.features?.forEach((feat: any) => {
      if (isBlueprintMap) {
        const tags_info = feat?.properties?.tags_info || {};
        if (feat?.properties && typeof feat?.properties === 'object') {
          feat.properties.tags_info = filterOutDeletedTags(tags_info, true)?.tags_info || {};
        }
      } else if (feat?.properties && feat?.properties?.tags_info) delete feat?.properties?.tags_info;
    });
    // const filteredGeoms = getFilteredGeoms({
    //   geoms: json?.features,
    //   featureTags: default_tags,
    // });
    // const filteredJSON = { ...json, features: filteredGeoms };

    const isParcel = id === PARCEL;
    const filteredJSON = isParcel ? transformMutiPolyToFeatures(json) : json;

    /*Sets the projection according to map_type aerial or blueprint */
    const projection = isBlueprintMap ? this.map?.getView().getProjection() : GEO_JSON_OBJECT_PROJECTION;

    /*Covert the geojson to features array which are to be appended on the layer(id) */
    const features = GEO_JSON.readFeatures(filteredJSON, projection as any);

    const { AppStore } = globalStore;
    /*gets the list of all layers appended which contains the meta data/data of layers */
    const { layers } = AppStore;
    const geometry = layers?.get(id)?.geometry_type || 0;

    const _source = new VectorSource({
      features,
      wrapX: false
    });
    // _source.convertMultiGeometryFeatures();
    _source.on('addfeature', this.onAddFeature);
    /*called when there is a change in  layer(id) and saves the change to backend */
    _source.on(['changefeature', 'addfeature', 'removefeature'], this.saveData);
    // // this.previousLayersState = this.getLayersFromJob();\

    const { scale, dpi } = globalStore.AppStore.worksheetParams;
    const geometry_type = isBlueprintMap ? getLayerType(id)?.geometry_type : getAerialGeometryType(id);
    let newStyle;

    /*Different styles for overlay layers */
    if ([HIGHLIGHT_TOOL_LAYER, AUTO_SCALE_TOOL_LAYER].includes(id)) {
      newStyle = (feature: any) => styleFunctionHightTool(feature);
    } else if (id === DIMENSION_TOOL_LAYER) {
      newStyle = (feature: any) => styleFunctionDimenSionTool(feature, scale, dpi);
    } else if (id === ARROW_TOOL) {
      newStyle = (feature: any) => styleFunctionArrow(feature, 'red');
    } else if (id === HAND_TOOL_LAYER) {
      newStyle = (feature: any) => styleFunctionHandTool(feature);
    } else {
      newStyle = ModifiedStyleGenFn(id, layers, opacity, geometry_type);
    }

    let _layer = this.getLayerById(id);
    /*if layer with {id} is already mounted on map so just updates it with newStyle and source  
        else add new layer*/

    if (!_layer) {
      _layer = new VectorLayer({
        // @ts-ignore id is private
        id,
        source: _source,
        style: newStyle,
        zIndex: getLayerType(id)?.z_index ?? GEOMETRY_Z_INDEX[geometry] ?? 100,
        // zIndex: getLayerType(id)?.z_index ?? 100,
        geometryType: geometry_type,
        ...(isBlueprintMap && { default_tags }),
        is_annotation_layer: isAnnotationLayer,
        ...(isBlueprintMap && { declutter: true })
      });
      if (this.map) this.map?.addLayer(_layer);
    } else {
      _layer.setSource(_source);
      _layer.setStyle(newStyle);
    }

    if (!layerVisibility) {
      _layer.setVisible(false);
    }

    return _source;
  }

  /**
   * Loads a Blueprint (BPT) layer onto the map based on the provided page information.
   * Updates the map view and adds the BPT layer with the specified image.
   * @param {CurrentSheet} page - The page information containing the ID, width, height, and image URL.
   */
  async loadBPTlayer(page: CurrentSheet) {
    const { AppStore } = globalStore;
    if (!page.id) return;
    this.map?.set('PROJECTED_WIDTH', page.width);
    this.map?.set('PROJECTED_HEIGHT', page.height);
    this.map?.set('PAGE_BASE_IMAGE', page.image);

    const modifiedExtend: [number, number, number, number] = [0, -page.height, page.width, 0];
    const PADDING = 1500;
    const extent: [number, number, number, number] = [
      0 - PADDING,
      -page.height - PADDING,
      page.width + PADDING,
      0 + PADDING
    ];
    const isTileServerAva = await fetch(
      `${TILE_SERVER_URL}/${page.source_file_id}/${parseInt(page.page_no)}/0/0/0.png`
    );

    AppStore.updateState({ extendCoordinates: extent });

    const center = getCenter(extent);
    const projection = new Projection({
      code: BPT_PROJECTION,
      units: 'pixels',
      extent: modifiedExtend,
      global: true
    });

    AppStore.setBptProjection(projection);

    const view = new View({
      projection,
      extent,
      center,
      zoom: 2,
      minZoom: MIN_ZOOM,
      showFullExtent: true,
      maxZoom: 22
    });
    this.map?.setView(view);

    const baseLayer = new ImageLayer({
      // @ts-ignore
      id: 'BASE_LAYER',
      source: new Static({
        url: page.image,
        projection,
        imageExtent: modifiedExtend
      })
    });
    this.map?.addLayer(baseLayer);

    if (isTileServerAva.status === 200) {
      const [TILE_SIZE_WIDTH, TILE_SIZE_HEIGHT] = await getImageWidthAndHeight(
        `${TILE_SERVER_URL}/${page.source_file_id}/${parseInt(page.page_no)}/0/0/0.png`
      );
      const tileLayer = new TileLayer({
        // @ts-ignore
        id: 'BASE_LAYER_TILE',
        source: new XYZ({
          projection,
          maxZoom: MAX_ZOOM,
          minZoom: MIN_ZOOM,
          tileUrlFunction(tileCoord) {
            const z = tileCoord[0];
            const x = tileCoord[1];
            const y = tileCoord[2];
            return `${TILE_SERVER_URL}/${page.source_file_id}/${parseInt(page.page_no)}/${z}/${y}/${x}.png`;
          },
          tileSize: [TILE_SIZE_WIDTH, TILE_SIZE_HEIGHT],
          wrapX: false,
          tilePixelRatio: 1
        }),
        preload: 1,
        zIndex: 1,
        extent: modifiedExtend
      });
      this.map?.addLayer(tileLayer);
    }
  }

  /**
   * Event handler for when a feature is added to a vector layer.
   * Converts the geometry of multi-geometries if necessary and highlights polygons if they are invalid.
   * @param {any} e - The event object containing information about the added feature.
   */
  onAddFeature = (e: any) => {
    const { feature } = e;

    const geomType = feature.getGeometry().getType();
    if (MULTI_GEOMETRIES.includes(geomType)) {
      e.target.convertFeatureGeometry(feature);
    }
    if (geomType === GEOMETRY_TYPES.POLYGON) {
      highlightFeatureIfInvalid(feature);
    }

    const taggingFilterQuery = useTags.getState()?.filterQuery;
    if (taggingFilterQuery?.tagsInfo) {
      showToast("Filter won't be applied on newly created geometries. You can reapply!", 'info', {
        pauseOnHover: true
      });
    }
  };

  getLayerName = (layer: any) => {
    if (!(layer instanceof BaseLayer)) {
      return this.getLayerById(layer)?.get('name');
    }
    return layer?.get('name');
  };

  /**
   * Returns an array of Snap interactions for snapping to features on vector layers.
   * @param {number} [tolerance=10] - The pixel tolerance for snapping.
   * @returns {Array<Snap>} An array of Snap interactions.
   */
  getSnap(tolerance: number = 10, edgeSnapping: boolean = false): Array<Snap> {
    if (!globalStore.AppStore.tool.snapping_mode) return [];
    const layers = this.map
      ?.getLayers()
      .getArray()
      .filter(
        layer =>
          layer.get('id') !== PARCEL &&
          (getLayerType(layer.get('id'))?.geometry_type === GEOMETRY_TYPE.POLYGON ||
            getLayerType(layer.get('id'))?.geometry_type === GEOMETRY_TYPE.LINE ||
            getLayerType(layer.get('id'))?.geometry_type === GEOMETRY_TYPE.POINT ||
            getLayerType(layer.get('id'))?.geometry_type === GEOMETRY_TYPE.ABSTRACT)
      );

    const snaps: Array<Snap> = [];
    layers?.forEach(layer => {
      const snap = new Snap({
        //@ts-ignore
        source: layer.getSource(),
        pixelTolerance: tolerance,
        edge: edgeSnapping
      });
      snaps.push(snap);
    });
    return snaps;
  }
}

export default MapBase;
